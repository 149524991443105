import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./tableDatagrid.scss";

const TableDatagrid = ({
  rows = [],
  columns = [],
  pageSize = 10,
  rowsPerPageOptions = 10,
  onSelectionModelChange = () => {},
  checkboxSelection = true,
  isLoading = false,
  initialState = {},
}) => {
  return (
    <DataGrid
      loading={isLoading}
      className="table-datagrid"
      rows={rows}
      columns={columns}
      onSelectionModelChange={onSelectionModelChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
        ...initialState,
      }}
    />
  );
};
export default TableDatagrid;
