import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../context/SnackbarContext";
import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import UploadFile from "./components/uploadDataset/UploadFile";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api";
import PreviewFile from "./components/uploadDataset/PreviewFile";
import DetailsFormFile from "./components/uploadDataset/DetailsFormFile";
import NotFound from "../404notfound/NotFound";
import { AuthContext } from "../../context/AuthContext";
import { remoteGetCleanroom } from "../../utils/firebase/cleanroom/remoteGetCleanroom";

const AddCleanRoomDataset = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fileValues, setFileValues] = useState({
    sample: [],
    csvFile: null,
  });
  const [fileValuesDataTypes, setFileValuesDataTypes] = useState({});
  const [values, setValues] = useState({
    collaborators: [],
    userId: "",
  });
  const { currentUser } = useContext(AuthContext);
  const [formStep, setFormStep] = useState(1);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const { handleSubmit: handleFormSubmit, formState, register } = useForm();
  const { errors } = formState;

  useEffect(() => {
    const detectColumnTypes = (data) => {
      if (!data || data.length === 0) return {};

      const columnTypes = {};
      const headers = Object.keys(data[0]); // Assuming first row has keys

      headers.forEach((header) => {
        const values = data
          .slice(0, 11) // to check data of top 10  rows
          .map((row) => row[header])
          .filter((val) => val !== null && val !== undefined);

        const types = values.map((value) => {
          if (!isNaN(value) && value.trim() !== "") return "number"; // Numeric check
          if (value.toLowerCase() === "true" || value.toLowerCase() === "false")
            return "boolean"; // Boolean check
          if (Date.parse(value)) return "date"; // Date check
          return "string"; // Default type
        });

        // Determine the most common type
        const typeCounts = types.reduce((acc, type) => {
          acc[type] = (acc[type] || 0) + 1;
          return acc;
        }, {});

        columnTypes[data[0][header]] = Object.keys(typeCounts).reduce((a, b) =>
          typeCounts[a] > typeCounts[b] ? a : b
        );
      });

      return columnTypes;
    };
    const output = detectColumnTypes(fileValues.sample);
    // console.log("output", output);

    setFileValuesDataTypes(output);
  }, [fileValues.sample]);

  useEffect(() => {
    if (id) {
      const promises = async () => {
        setIsLoading(true);
        const [cleanRoomData] = await Promise.all([
          remoteGetCleanroom(id, snackbarDispatch),
        ]);
        setValues({
          userId: cleanRoomData.userId || "",
          collaborators: cleanRoomData.collaborators || [],
          expireDate: cleanRoomData.expireDate || "",
        });

        if (
          cleanRoomData?.collaborators?.filter(
            (coll) => coll.id === currentUser.uid
          )?.[0]?.status === "Pending"
        ) {
          setIsInvitationAccepted(false);
        } else {
          setIsInvitationAccepted(true);
        }

        setIsLoading(false);
      };
      promises();
    }
  }, [currentUser.uid, id, snackbarDispatch]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const handleBack = () => {
    setFormStep(formStep - 1);
  };

  const handleUploadFile = async () => {
    if (!fileValues.csvFile) {
      snackbarDispatch({
        type: "SHOW_WARNING_SNACKBAR",
        payload: "Go Back and please select a file to upload.",
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", fileValues.csvFile);
    try {
      const response = await api(
        API_REQUEST_TYPE.POST,
        API_CONFIG.END_POINTS.CLEAN_ROOM.UPLOAD_DATASET(id),
        formData
      );
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error("Error uploading file with result: ", response);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpdateCleanRoom = async (data) => {
    setIsLoading(true);
    const datasetId = await handleUploadFile();
    const datasetRefId = datasetId?.file_ref_id;
    if (!datasetRefId) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error uploading dataset",
      });
      return;
    }
    try {
      // Reference to the user's document
      const userDocRef = doc(db, "clean_room", id);
      const datasetsColRef = collection(userDocRef, "datasets");
      await addDoc(datasetsColRef, {
        datasetName: data.tableName,
        createDate: Date.now(),
        datasetColumns: fileValues.sample?.[0].map((value) => value),
        fileName: fileValues.csvFile.name,
        description: data.description,
        fileRefId: datasetRefId,
        userId: user.uid,
        userEmail: user.userDetails.email,
        types: fileValuesDataTypes,
      });
      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Dataset submitted successfully.",
      });
      setIsLoading(false);
      navigate(`/cleanroom/${id}`);
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error saving dataset details.",
      });
      console.error("Error adding dataset: ", error);
    }
    setIsLoading(false);
  };

  const submitDisabled = useMemo(() => !fileValues.csvFile, [fileValues]);

  const handleSubmit = (data) => {
    switch (formStep) {
      case 1:
        setFormStep(formStep + 1);
        break;
      case 2:
        setFormStep(formStep + 1);
        break;
      case 3:
        handleUpdateCleanRoom(data);
        break;
      default:
        break;
    }
  };

  const renderStepForm = () => {
    switch (formStep) {
      case 1:
        return (
          <UploadFile fileValues={fileValues} setFileValues={setFileValues} />
        );
      case 2:
        return <PreviewFile fileValues={fileValues} />;
      case 3:
        return (
          <DetailsFormFile
            fileValues={fileValues}
            register={register}
            errors={errors}
          />
        );
      default:
        <></>;
    }
  };

  const isAuthenticated = useMemo(
    () =>
      !!values.collaborators.find((col) => col?.id === user?.uid) ||
      values.userId === user?.uid,
    [values, user]
  );

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (values === null || !isAuthenticated) {
    return <NotFound pageName="clean room" />;
  }
  const isExpired = new Date().valueOf() > values?.expireDate;

  if (!isInvitationAccepted || isExpired) {
    return (
      <NotFound
        pageName="clean room"
        message={
          isExpired
            ? "Data cannot be added after cleanroom is expired."
            : "Accept Collaboration invitation to access this page."
        }
        btnNavigatePath={`/cleanroom/${id}`}
      />
    );
  }

  return (
    <div className="bg-white h-full overflow-auto">
      <form
        className="px-[50px]"
        onSubmit={handleFormSubmit(handleSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="flex flex-col gap-[40px]">
          <div className="bg-white py-[40px] mx-10">
            <div className="flex flex-col gap-[48px]">
              <div className="flex flex-wrap justify-between">
                <div className="flex flex-col flex-wrap gap-4 w-full">
                  <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                    Add Data
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    Creating a data clean room guarantees the confidentiality
                    and security of data.
                  </Typography>
                </div>
              </div>
              {renderStepForm()}
              <div className="flex justify-between items-center gap-10">
                <div className="w-[200px]">
                  <Button
                    type="button"
                    variant={BUTTON_VARIANT.SECONDARY}
                    onClick={() =>
                      navigate(`/cleanroom/${id}`, {
                        state: { tabName: "Data" },
                      })
                    }
                  >
                    CANCEL
                  </Button>
                </div>
                <div className="flex justify-end gap-10">
                  {formStep !== 1 && (
                    <Button
                      variant={BUTTON_VARIANT.SECONDARY}
                      type="button"
                      onClick={handleBack}
                      className="w-[300px]"
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant={
                      submitDisabled
                        ? BUTTON_VARIANT.DISABLED
                        : BUTTON_VARIANT.PRIMARY
                    }
                    type="submit"
                    className="w-[300px]"
                    disabled={submitDisabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCleanRoomDataset;
