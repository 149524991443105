import { Link } from "react-router-dom";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import { useRef, useState } from "react";
import QuickStartModal from "../modals/QuickStartModal";
import LightBulb from "../svg/LightBulb";
import Campaign from "../svg/Campaign";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuickStartContent from "./QuickStartContent";

const QuickStartDrawer = ({
  show = false,
  hideDrawer = () => {},
  isSidebarCompressed = false,
}) => {
  const [quickStartModal, setQuickStartModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("Clean Room");
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    if (!item) {
      setAnchorEl("Project");
      setSelectedIndex("Project");
      return;
    }
    setAnchorEl(item);
    setSelectedIndex(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const inputEl = useRef(null);

  return (
    <div
      className={`fixed top-0 z-40 h-screen overflow-y-auto transition-transform  w-full dark:bg-gray-800 bg-[#0000004d] ${
        show ? "translate-x-unset" : "translate-x-fulls hidden"
      } ${isSidebarCompressed ? "left-[256px]" : "left-[64px]"}`}
      tabIndex={-1}
      aria-labelledby="drawer-right-label"
    >
      <div className="w-[50vw] me-auto h-full ml-[20px] relative">
        <div
          className="absolute bottom-[80px] flex flex-wrap flex-col h-[80%] justify-between mb-8 gap-4 px-5"
          onClick={() => {}}
        >
          <div className="bg-white mt-auto rounded-lg border-2 border-slate-300 max-w-[380px] overflow-auto pt-0">
            <div className="bg-[#A0CCFB] py-[10px] flex gap-2">
              <div className="flex">
                <div className="ml-[10px]">
                  <LightBulb fill="#2C2C2C" />
                </div>
                <div className="ml-[10px]">
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                    Quickstart
                  </Typography>
                </div>
              </div>
              <div
                className="ml-[auto] mr-[10px] cursor-pointer hidden"
                onClick={!open ? handleClick : handleCloseMenu}
                id="lock-menu"
              >
                <Link>
                  <KeyboardArrowDownIcon ref={inputEl} />
                </Link>
              </div>
              <Menu
                anchorEl={inputEl.current}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
                id="menu-appbar"
              >
                <MenuItem
                  key={"Project"}
                  selected={selectedIndex === "Project"}
                  onClick={(event) => handleClick(event, "Project")}
                >
                  Project
                </MenuItem>
                <MenuItem
                  key={"Clean Room"}
                  selected={selectedIndex === "Clean Room"}
                  onClick={(event) => handleClick(event, "Clean Room")}
                >
                  Clean Room
                </MenuItem>
              </Menu>
            </div>
            <QuickStartContent type={selectedIndex} />
            <div
              className="mt-[30px] bg-[#A0CCFB] bg-opacity-21 flex gap-2 py-[10px] cursor-pointer hover:bg-[#7fbaf9]"
              onClick={() => setQuickStartModal(true)}
            >
              <div className="ml-[40px]">
                <Campaign />
              </div>
              <div>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  <Link>Leave Feedback</Link>
                </Typography>
              </div>
            </div>
            <div
              className="py-[15px] mt-[10px] bg-[#DDDDDD] cursor-pointer hover:bg-[#d3d3d3]"
              onClick={hideDrawer}
            >
              <div className="text-center">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  <Link className="text-[#323582]">Dismiss Quickstart</Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {quickStartModal && (
        <>
          <QuickStartModal handleClose={() => setQuickStartModal(false)} />
        </>
      )}
    </div>
  );
};

export default QuickStartDrawer;
