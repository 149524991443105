import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import SelectField, {
  LABEL_TYPE,
} from "../../../../components/common/SelectField";
import { useMemo } from "react";

const ColumnSelectionTaskForm = ({
  control,
  register,
  errors,
  datasetsData,
  watch,
}) => {
  const [
    seletedtable1Id,
    seletedtable2Id,
    seletedtable1IsDatasetOwner,
    seletedtable2IsDatasetOwner,
  ] = watch([
    "table1.id",
    "table2.id",
    "table1.isDatasetOwner",
    "table2.isDatasetOwner",
  ]);

  const groupByColumnOptions = useMemo(() => {
    const table1ColumnOptions = seletedtable1IsDatasetOwner
      ? datasetsData
          .find((dataset) => dataset.id === seletedtable1Id)
          ?.datasetColumns?.map((columnName) => {
            return { value: `t1.${columnName}`, label: `t1.${columnName}` };
          })
      : [];

    const table2ColumnOptions = seletedtable2IsDatasetOwner
      ? datasetsData
          .find((dataset) => dataset.id === seletedtable2Id)
          ?.datasetColumns?.map((columnName) => {
            return { value: `t2.${columnName}`, label: `t2.${columnName}` };
          })
      : [];
    return [...(table1ColumnOptions ?? []), ...(table2ColumnOptions ?? [])];
  }, [
    seletedtable1Id,
    seletedtable2Id,
    seletedtable1IsDatasetOwner,
    seletedtable2IsDatasetOwner,
    datasetsData,
  ]);

  const disableGroupBy =
    !seletedtable1IsDatasetOwner && !seletedtable2IsDatasetOwner;

  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Select Column
      </Typography>
      <div className="flex mt-3 gap-5">
        <div className="flex-1">
          <SelectField
            control={control}
            labelType={LABEL_TYPE.OUTLINED}
            options={groupByColumnOptions}
            multiple={true}
            placeholder="Choose Column"
            isDisabled={disableGroupBy}
            innerRef={register(`groupByColumn`).ref}
            {...register(`groupByColumn`, {
              // required: "Group by Column is required.",
            })}
            errorMessage={errors?.groupByColumn?.message}
            isError={!!errors?.groupByColumn?.message}
          />
        </div>
        <div className="flex-1" />
      </div>
    </div>
  );
};

export default ColumnSelectionTaskForm;
