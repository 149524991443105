import { useEffect, useMemo } from "react";
import InputTextArea from "../../../../components/common/InputTextArea";
import InputTextField, {
  LABEL_TYPE,
} from "../../../../components/common/InputTextField";
import SelectField from "../../../../components/common/SelectField";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import { INITIAL_CONDITION_FIELD_VALUES } from "./ConditionsTaskForm";
import { INITIAL_AGGREGATE_FIELD_VALUES } from "./AggregateTaskForm";

const ConfigurationTaskForm = ({
  control,
  register,
  errors,
  datasetsData,
  watch,
  setValue,
  currentUserId,
  resetField,
}) => {
  const [seletedtable1Id, seletedtable2Id] = watch(["table1.id", "table2.id"]);

  useEffect(() => {
    const selectedTable = datasetsData.find(
      (data) => data.id === seletedtable1Id
    );
    setValue("table1.name", selectedTable?.datasetName);
    setValue("table1.fileRefId", selectedTable?.fileRefId);
    setValue("table1.isDatasetOwner", selectedTable?.userId === currentUserId);
  }, [seletedtable1Id, datasetsData, setValue, currentUserId]);

  useEffect(() => {
    const selectedTable = datasetsData.find(
      (data) => data.id === seletedtable2Id
    );
    setValue("table2.name", selectedTable?.datasetName);
    setValue("table2.fileRefId", selectedTable?.fileRefId);
    setValue("table2.isDatasetOwner", selectedTable?.userId === currentUserId);
  }, [seletedtable2Id, datasetsData, setValue, currentUserId]);

  useEffect(() => {
    if (seletedtable1Id && seletedtable2Id) {
      // Clear other values when specificField updates
      setValue("groupByColumn", []);
      resetField("join.table1Column");
      resetField("join.table2Column");
      resetField("condition", {
        defaultValue: [INITIAL_CONDITION_FIELD_VALUES],
      });
      resetField("aggregateFun", {
        defaultValue: [INITIAL_AGGREGATE_FIELD_VALUES],
      });
    }
  }, [seletedtable1Id, seletedtable2Id, setValue, resetField]);

  const table1NameOptions = useMemo(
    () =>
      datasetsData
        ?.filter((data) => data.id !== seletedtable2Id)
        ?.map((data) => {
          return {
            value: data.id,
            label: data.datasetName,
            isCurrentUsers: data.userId === currentUserId,
          };
        }),
    [datasetsData, seletedtable2Id, currentUserId]
  );

  const table2NameOptions = useMemo(
    () =>
      datasetsData
        ?.filter((data) => data.id !== seletedtable1Id)
        ?.map((data) => {
          return {
            value: data.id,
            label: data.datasetName,
            isCurrentUsers: data.userId === currentUserId,
          };
        }),
    [datasetsData, seletedtable1Id, currentUserId]
  );

  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Configuration
      </Typography>
      <div className="flex">
        <Typography
          variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
          className="flex-2"
        >
          Task Name
        </Typography>
        <div className="flex-1">
          <InputTextField
            labelType={LABEL_TYPE.OUTLINED}
            placeholder="Enter Task Name"
            type="text"
            innerRef={register("taskName").ref}
            {...register("taskName", {
              required: "Task Name is required.",
            })}
            errorMessage={errors?.taskName?.message}
            isError={!!errors?.taskName?.message}
          />
        </div>
      </div>
      <div className="flex">
        <Typography
          variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
          className="flex-2"
        >
          Description
        </Typography>
        <div className="flex-1">
          <InputTextArea
            labelType={LABEL_TYPE.OUTLINED}
            placeholder="Enter Description (Optional)"
            rows={5}
            type="text"
            innerRef={register("description").ref}
            {...register("description")}
            errorMessage={errors?.description?.message}
            isError={!!errors?.description?.message}
          />
        </div>
      </div>
      <div className="flex">
        <Typography
          variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
          className="flex-2"
        >
          Table
        </Typography>
        <div className="flex-1">
          <SelectField
            control={control}
            labelType={LABEL_TYPE.OUTLINED}
            options={table1NameOptions}
            placeholder="Select Table 1"
            innerRef={register("table1.id").ref}
            {...register("table1.id", {
              required: "Table 1 is required.",
            })}
            errorMessage={errors?.table1?.id?.message}
            isError={!!errors?.table1?.id?.message}
          />
          <SelectField
            control={control}
            labelType={LABEL_TYPE.OUTLINED}
            options={table2NameOptions}
            placeholder="Select Table 2"
            innerRef={register("table2.id").ref}
            {...register("table2.id", {
              required: "Table 2 is required.",
            })}
            errorMessage={errors?.table2?.id?.message}
            isError={!!errors?.table2?.id?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationTaskForm;
