const createSqlQuery = (data) => {
  const {
    condition,
    aggregateFun,
    table1,
    table2,
    joinCondition,
    join,
    groupByColumn,
  } = data;

  // Extract WHERE conditions
  const whereConditions = condition
    .map((cond) => `${cond.column} ${cond.where} '${cond.fieldValue}'`)
    .join(" AND ");

  // Extract aggregate functions
  const aggregates = aggregateFun
    .map((agg) => `${agg.aggregate}(${agg.column})`)
    .join(", ");

  // Create GROUP BY clause
  const groupBy = groupByColumn ? groupByColumn?.join(", ") : "";

  // Build the SQL query
  const query = `SELECT ${groupBy}${aggregates ? `, ${aggregates}` : ""} FROM ${
    table1.name
  } t1 ${joinCondition.toUpperCase()} JOIN ${table2.name} t2 ON t1.${
    join.table1Column
  } = t2.${join.table2Column} ${
    whereConditions ? `WHERE ${whereConditions}` : ""
  } ${groupBy ? `GROUP BY ${groupBy}` : ""};`;

  return query.trim();
};
export default createSqlQuery;
