import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import ArrowBackHome from "../../components/svg/ArrowBackHome";

const NotFound = ({ pageName = "page", message, btnNavigatePath }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(btnNavigatePath ?? "/cleanroom");
  };

  return (
    <div className="flex flex-col mt-[30vh] items-center bg-404 opacity-80 justify-center">
      <Typography
        variant={TYPOGRAPHY_VARIANT.HEADING_3}
        className="text-center mb-4"
      >
        "So sorry, the {pageName} couldn’t be found.
      </Typography>
      <Typography
        variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
        className="text-center mb-4"
      >
        {message ? (
          message
        ) : (
          <>
            You may have mistyped the address or the page could have been moved.
          </>
        )}
      </Typography>
      <div class="text-[440px] mt-[25vh] opacity-10 absolute font-bold text-gray-500">
        <span>404</span>
      </div>
      <div className="ml-[28%]  mr-[28%] mt-[30px]">
        <Button
          onClick={handleClick}
          buttonClassName={"flex"}
          variant={"primary"}
        >
          BACK TO THE HOMEPAGE <ArrowBackHome />
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
