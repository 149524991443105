import InputTextArea from "../../../../components/common/InputTextArea";
import InputTextField, { LABEL_TYPE } from "../../../../components/common/InputTextField";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";

const DetailsFormFile = ({ fileValues, register, errors }) => {
  return (
    <div className="flex gap-5 relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        File Information
      </Typography>
      <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5 bg-[#f7f7f7]">
        <div className="flex flex-col justify-between">
          <div className="flex flex-row">
            <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
              File Name
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              {fileValues.csvFile.name}
            </Typography>
          </div>
          <div className="flex flex-row mt-5">
            <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
              Column
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              {fileValues.sample?.[0].length} fields
            </Typography>
          </div>
          <div className="flex flex-row mt-5">
            <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>Row</Typography>
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              {fileValues.sample.length} rows
            </Typography>
          </div>
        </div>
      </div>
      <div className="border-grey border-[1px] rounded-3xl p-5">
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Table Name
              </Typography>
              <div className="flex-1">
                <InputTextField
                  labelType={LABEL_TYPE.OUTLINED}
                  placeholder="Enter Table Name"
                  type="text"
                  innerRef={register("tableName").ref}
                  {...register("tableName", {
                    required: "Table Name is required.",
                  })}
                  errorMessage={errors?.tableName?.message}
                  isError={!!errors?.tableName?.message}
                />
              </div>
            </div>
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Description
              </Typography>
              <div className="flex-1">
                <InputTextArea
                  labelType={LABEL_TYPE.OUTLINED}
                  placeholder="Enter Description (Optional)"
                  rows={5}
                  type="text"
                  innerRef={register("description").ref}
                  {...register("description")}
                />
              </div>
            </div>
          </div>
    </div>
  );
};

export default DetailsFormFile;
