import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

export const remoteGetCleanroomAllDatasets = async (
  cleanRoomId,
  snackbarDispatch
) => {
  try {
    const cleanRoomRef = doc(db, "clean_room", cleanRoomId);
    const datasetsColRef = collection(cleanRoomRef, "datasets");
    const cleanRoomDatasetSnapshot = await getDocs(datasetsColRef);

    const fetchedDatasets = cleanRoomDatasetSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return fetchedDatasets;
  } catch (error) {
    snackbarDispatch({
      type: "SHOW_ERROR_SNACKBAR",
      payload: "Error while fetching Clean Room Datasets.",
    });
    console.error("Error fetching dataset:", error);
    return [];
  }
};
