import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../context/SnackbarContext";
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import NotFound from "../404notfound/NotFound";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import InputTagContainer from "../../components/common/InputTagContainer";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import checkEmailRegistered from "../../utils/firebase/checkEmailRegistered";
import addUnregisteredCollaborator from "../../utils/firebase/addUnregisteredCollaborator";
import sendEmail, { EMAIL_TEMPLATE } from "../../utils/sendEmail";
import CleanRoomDetails from "./components/CleanRoomDetails";
import { remoteGetCleanroom } from "../../utils/firebase/cleanroom/remoteGetCleanroom";

const AddCleanRoomCollaborator = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    description: "",
    createDate: null,
    expireDate: null,
    collaborators: [],
  });
  const [emailTagRegisteredUserDetails, setEmailTagRegisteredUserDetails] =
    useState([]);
  const [dbOperatorTags, setDbOperatorTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const {
    setError,
    handleSubmit: handleFormSubmit,
    formState,
    clearErrors,
  } = useForm();
  const { errors } = formState;

  const collaboratorsEmailPresent = useMemo(
    () => values.collaborators.map((col) => col.email),
    [values]
  );

  useEffect(() => {
    if (id) {
      const promises = async () => {
        setIsLoading(true);
        const [cleanRoomData] = await Promise.all([
          remoteGetCleanroom(id, snackbarDispatch),
        ]);

        setValues({
          name: cleanRoomData.name || "",
          description: cleanRoomData.description || "",
          createDate: cleanRoomData.createDate || "",
          expireDate: cleanRoomData.expireDate || "",
          userId: cleanRoomData.userId || "",
          collaborators: cleanRoomData.collaborators || [],
        });

        if (
          cleanRoomData?.collaborators?.filter(
            (coll) => coll.id === currentUser.uid
          )?.[0]?.status === "Pending"
        ) {
          setIsInvitationAccepted(false);
        } else {
          setIsInvitationAccepted(true);
        }

        setIsLoading(false);
      };
      promises();
    }
  }, [currentUser.uid, id, snackbarDispatch]);

  useEffect(() => {
    const updateEmailRepeatedError = () => {
      const repeatedEmails = collaboratorsEmailPresent.filter((value) =>
        dbOperatorTags.includes(value)
      );

      if (repeatedEmails.length) {
        setError("dataOperetor", {
          type: "custom",
          message: `${repeatedEmails.join(", ")} is already a collaborator`,
        });
      } else {
        clearErrors("dataOperetor");
      }
    };

    updateEmailRepeatedError();
  }, [dbOperatorTags, clearErrors, collaboratorsEmailPresent, setError]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const handleAddNewTag = async (newTag) => {
    const emailUserDetails = await checkEmailRegistered(newTag);
    if (emailUserDetails) {
      setEmailTagRegisteredUserDetails({
        ...emailTagRegisteredUserDetails,
        [emailUserDetails?.email]: emailUserDetails,
      });
    }
  };

  const handleRemoveTag = async (remaingTags) => {
    // updating emailTagRegisteredUserDetails
    let remainingTagValues = {};
    remaingTags.forEach((tag) => {
      remainingTagValues[tag] = emailTagRegisteredUserDetails[tag];
    });
    setEmailTagRegisteredUserDetails(remainingTagValues);
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);

    try {
      const collaborationData = [];
      dbOperatorTags.forEach((operatorEmail) => {
        if (!collaboratorsEmailPresent.includes(operatorEmail)) {
          const registered = Object.keys(
            emailTagRegisteredUserDetails
          ).includes(operatorEmail);

          collaborationData.push({
            email: operatorEmail,
            ability: "Contribute Data",
            status: "Pending",
            registered: registered,
            firstName:
              emailTagRegisteredUserDetails[operatorEmail]?.firstName || "",
            id: emailTagRegisteredUserDetails[operatorEmail]?.id || "",
          });
        }
      });
      const newCleanRoomData = {
        collaborators: [...values.collaborators, ...collaborationData],
      };
      const docRef = doc(db, "clean_room", id);
      await updateDoc(docRef, newCleanRoomData);

      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Collaborators Added successfully.",
      });

      collaborationData.forEach(async (collaborator) => {
        if (!collaborator.registered) {
          //make unregistered user if user is not registered
          await addUnregisteredCollaborator(
            collaborator.email,
            "cleanRoomId",
            id
          );
        }
        //send email
        await sendEmail(
          collaborator.email,
          EMAIL_TEMPLATE.CLEAN_ROOM_INVITATION,
          {
            toFirstName: collaborator.firstName
              ? collaborator.firstName
              : collaborator.email.split("@")[0],
            cleanRoomName: values.name,
            cleanRoomId: id,
            currentUserName: currentUser.userDetails.firstName,
          }
        );
      });

      setIsLoading(false);
      navigate(`/cleanroom/${id}`);
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while adding collaborators.",
      });
      console.error(error);
      setIsLoading(false);
    }
  };

  const submitDisabled = useMemo(
    () => !dbOperatorTags.length,
    [dbOperatorTags]
  );

  const isAuthenticated = useMemo(
    () =>
      !!values.collaborators.find((col) => col?.id === user?.uid) ||
      values.userId === user?.uid,
    [values, user]
  );

  const isExpired = new Date().valueOf() > values?.expireDate;

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (values === null || !isAuthenticated) {
    return <NotFound pageName="clean room" />;
  }

  if (!isInvitationAccepted && isExpired) {
    return (
      <NotFound
        pageName="clean room"
        message={
          isExpired
            ? "Collaborators cannot be added after cleanroom is expired."
            : "Accept Collaboration invitation to access this page."
        }
        btnNavigatePath={`/cleanroom/${id}`}
      />
    );
  }

  return (
    <div className="bg-white h-full overflow-auto">
      <form
        className="px-[50px]"
        onSubmit={handleFormSubmit(handleSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="flex flex-col gap-[40px]">
          <div className="bg-white py-[40px] mx-10">
            <div className="flex flex-col gap-[48px]">
              <div className="flex flex-wrap justify-between">
                <div className="flex flex-col flex-wrap gap-4 mb-8 w-full">
                  <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                    Add Collaborator
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    Collaborate to uncover deeper insights and unlock the full
                    potential of your data.
                  </Typography>
                </div>
              </div>
              <CleanRoomDetails values={values} />
              <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
                <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
                  Invite Collaborator
                </Typography>
                <div className="flex">
                  <Typography
                    variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                    className="flex-2"
                  >
                    Company Email
                  </Typography>
                  <div className="flex-1">
                    <InputTagContainer
                      errorMessage={errors?.dataOperetor?.message}
                      isError={!!errors?.dataOperetor?.message}
                      tags={dbOperatorTags}
                      setTags={setDbOperatorTags}
                      onAddNewTag={handleAddNewTag}
                      onRemoveTag={handleRemoveTag}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[16px] justify-end justify-self-end justify-item-end">
                <Button
                  type="button"
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={() =>
                    navigate(`/cleanroom/${id}`, {
                      state: { tabName: "Collaborator" },
                    })
                  }
                  className="w-[300px]"
                >
                  CANCEL
                </Button>
                <Button
                  variant={
                    submitDisabled || errors.dataOperetor
                      ? BUTTON_VARIANT.DISABLED
                      : BUTTON_VARIANT.PRIMARY
                  }
                  type="submit"
                  className="w-[300px]"
                  disabled={submitDisabled}
                >
                  Add Collaborator
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCleanRoomCollaborator;
