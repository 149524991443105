import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const addUnregisteredCollaborator = async (email, idType, targetId) => {
  try {
    const unregisteredCollRef = doc(db, "unregistered_collaborators", email);
    const datasetSnapshot = await getDoc(unregisteredCollRef);
    if (datasetSnapshot.exists()) {
      const existingData = datasetSnapshot.data();
      await updateDoc(unregisteredCollRef, {
        [idType]: [...existingData[idType], targetId],
      });
    } else {
      await setDoc(unregisteredCollRef, {
        [idType]: [targetId],
      });
    }
  } catch (error) {
    console.error(`ERROR ADDING ${email} AS UNREGISTERED COLLABORATOR:`, error);
  }
};

export default addUnregisteredCollaborator;
