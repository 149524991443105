import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import OverlayLoader from "../../components/common/OverlayLoader";
import NotFound from "../404notfound/NotFound";
import { SnackbarContext } from "../../context/SnackbarContext";
import Tabs, { TAB_VARIANT } from "../../components/common/Tabs";
import CollaboratorInviteAction from "../../components/modals/CollaboratorInviteAction";
import { AuthContext } from "../../context/AuthContext";
import CollaboratorTab from "./components/tableTabs/CollaboratorTab";
import DatasetTab from "./components/tableTabs/DatasetTab";
import TaskTab from "./components/tableTabs/TaskTab";
import ArrowBack from "../../components/svg/ArrowBack";

const dateOptions = { day: "2-digit", month: "long", year: "numeric" };

const ViewCleanRoom = ({ user }) => {
  const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
    description: "",
    createDate: null,
    expireDate: null,
    collaborators: [],
    userId: "",
  });
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(
    location?.state?.tabName || "Collaborator"
  );
  const [collaboratorTableData, setCollaboratorTableData] = useState([]);
  const [datasetTableData, setDatasetTableData] = useState([]);
  const [taskTableData, setTaskTableData] = useState([]);
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const [owner, setOwner] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDatasetLoading, setIsDatasetLoading] = useState(true);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [collaboratorActionModalMessage, setCollaboratorActionModalMessage] =
    useState("");
  const [isCollaboratorActionModalOpen, setIsCollaboratorActionModalOpen] =
    useState(false);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const fetchData = useCallback(async () => {
    try {
      const cleanRoomRef = doc(db, "clean_room", id);
      const cleanRoomSnapshot = await getDoc(cleanRoomRef);
      if (cleanRoomSnapshot.exists()) {
        const cleanRoomData = cleanRoomSnapshot.data();

        setValues({
          name: cleanRoomData.name || "",
          description: cleanRoomData.description || "",
          createDate: cleanRoomData.createDate || "",
          expireDate: cleanRoomData.expireDate || "",
          userId: cleanRoomData.userId || "",
          collaborators: cleanRoomData.collaborators || [],
        });
        setCollaboratorTableData(cleanRoomData.collaborators);

        if (
          cleanRoomData.collaborators.filter(
            (coll) => coll.id === currentUser.uid
          )?.[0]?.status === "Pending"
        ) {
          openInvitationModal();
          setIsInvitationAccepted(false);
        } else {
          setIsInvitationAccepted(true);
        }

        const ownerRef = doc(db, "users", cleanRoomData.userId);
        const ownerSnapshot = await getDoc(ownerRef);
        if (ownerSnapshot.exists()) {
          const ownerData = ownerSnapshot.data();
          setOwner(ownerData);
        }

        setIsLoading(false);
      } else {
        setValues(null);
        setIsLoading(false);
        console.error("Clean Room not found!");
      }
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching Clean Room.",
      });
      console.error("Error fetching dataset:", error);
      setIsLoading(false);
    }
  }, [currentUser.uid, id, snackbarDispatch]);

  const fetchDatasets = useCallback(async () => {
    setIsDatasetLoading(true);
    try {
      const cleanRoomRef = doc(db, "clean_room", id);
      const datasetsColRef = collection(cleanRoomRef, "datasets");
      const cleanRoomDatasetSnapshot = await getDocs(datasetsColRef);

      const fetchedDatasets = cleanRoomDatasetSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDatasetTableData(fetchedDatasets);
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching Clean Room Datasets.",
      });
      console.error("Error fetching dataset:", error);
    }
    setIsDatasetLoading(false);
  }, [id, snackbarDispatch]);

  const fetchTasks = useCallback(async () => {
    setIsTaskLoading(true);
    try {
      const cleanRoomRef = doc(db, "clean_room", id);
      const tasksColRef = collection(cleanRoomRef, "tasks");
      const cleanRoomTaskSnapshot = await getDocs(tasksColRef);

      const fetchedTasks = cleanRoomTaskSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTaskTableData(fetchedTasks);
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching Clean Room Tasks.",
      });
      console.error("Error fetching tasks:", error);
    }
    setIsTaskLoading(false);
  }, [id, snackbarDispatch]);

  useEffect(() => {
    if (id) {
      fetchData();
      fetchDatasets();
      fetchTasks();
    }
  }, [id, fetchData, fetchDatasets, fetchTasks]);

  const isExpired = new Date().valueOf() > values?.expireDate;

  const openInvitationModal = () => {
    setIsCollaboratorActionModalOpen(true);
    setCollaboratorActionModalMessage(
      `You need to accept the invitation to collaborate on this Clean Room`
    );
  };

  const selectedTabData = useCallback(() => {
    switch (activeTab) {
      case "Collaborator":
        const ownerCollaboratorData = {
          ability: "Owner",
          email: owner.email,
          firstName: owner.firstName,
          status: "Approved",
          registered: true,
          id: "owner",
        };

        const collTableData = [
          ownerCollaboratorData,
          ...collaboratorTableData.map((coll, ind) => {
            return { ...coll, key: ind, id: !!coll.id ? coll.id : coll.email };
          }),
        ];
        return (
          <CollaboratorTab
            currentUser={currentUser}
            openInvitationModal={openInvitationModal}
            isLoading={isLoading}
            tableData={collTableData}
            showAddBtn={isInvitationAccepted && !isExpired}
          />
        );
      case "Data":
        return (
          <DatasetTab
            isLoading={isDatasetLoading}
            tableData={datasetTableData}
            showAddBtn={isInvitationAccepted && !isExpired}
            cleanRoomId={id}
          />
        );
      case "Task":
        return (
          <TaskTab
            isLoading={isTaskLoading}
            tableData={taskTableData}
            showAddBtn={isInvitationAccepted && !isExpired}
            cleanRoomId={id}
          />
        );
      default:
        return <></>;
    }
  }, [
    activeTab,
    owner,
    currentUser,
    isLoading,
    collaboratorTableData,
    isDatasetLoading,
    datasetTableData,
    taskTableData,
    isTaskLoading,
    isInvitationAccepted,
    id,
    isExpired,
  ]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const collaboratorInvitaionAction = (action) => {
    const collaborationData = [];
    for (const collaborator of values.collaborators) {
      if (collaborator.id === currentUser.uid) {
        collaborationData.push({
          ability: collaborator.ability,
          email: collaborator.email,
          firstName: collaborator.firstName,
          id: collaborator.id,
          status: action,
          registered: collaborator.registered,
        });
      } else {
        collaborationData.push({
          ability: collaborator.ability,
          email: collaborator.email,
          firstName: collaborator.firstName,
          id: collaborator.id,
          status: collaborator.status,
          registered: collaborator.registered,
        });
      }
    }
    const updatedData = {
      collaborators: collaborationData,
    };
    return updatedData;
  };

  const handleCollaboratorAccept = async () => {
    const docRef = doc(db, "clean_room", id);
    const updatedData = collaboratorInvitaionAction("Approved");
    await updateDoc(docRef, updatedData);
    setIsCollaboratorActionModalOpen(false);
    fetchData();
  };

  const isAuthenticated = useMemo(
    () =>
      !!values?.collaborators?.find((col) => col?.id === user?.uid) ||
      values?.userId === user?.uid,
    [values, user]
  );

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (values === null || !isAuthenticated) {
    return <NotFound pageName="clean room" />;
  }

  return (
    <div className="bg-white h-full overflow-auto">
      <div className="flex flex-col gap-[40px]">
        <div className="bg-white py-[40px] mx-10">
          <div className="flex flex-col gap-[48px]">
            <div className="flex flex-wrap justify-between">
              <div className="flex flex-wrap gap-4 mb-8 w-full items-center">
                <Link
                  to={"/cleanroom"}
                  className="flex gap-2 hover:fill-brand-marine-blue mr-5"
                >
                  <ArrowBack />
                </Link>
                <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                  {values.name}
                </Typography>
              </div>
            </div>
            <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                    Created By
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    {owner.firstName}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                    Date Created
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    {new Date(values?.createDate).toLocaleDateString(
                      "en-GB",
                      dateOptions
                    )}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                    Date Expires
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    {new Date(values?.expireDate).toLocaleDateString(
                      "en-GB",
                      dateOptions
                    )}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                    Status
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    {isExpired ? (
                      <span className="status Ended">Ended</span>
                    ) : (
                      <span className="status Active">Active</span>
                    )}
                  </Typography>
                </div>
              </div>
              <div className="flex flex-col mt-5">
                <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
                  Room Description
                </Typography>
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                  {values.description}
                </Typography>
              </div>
            </div>
            <div className="border-dark-grey border-[1px] rounded-3xl p-5">
              <Tabs
                variant={TAB_VARIANT.UNDERLINE}
                names={["Collaborator", "Data", "Task"]}
                active={activeTab}
                onChangeActive={handleTabClick}
              />
              {selectedTabData()}
            </div>
          </div>
        </div>
      </div>
      {isCollaboratorActionModalOpen && (
        <CollaboratorInviteAction
          handleClose={() => setIsCollaboratorActionModalOpen(false)}
          handleAccept={() => handleCollaboratorAccept()}
          showDeny={false}
          body={collaboratorActionModalMessage}
        />
      )}
    </div>
  );
};

export default ViewCleanRoom;
