import { useEffect, useState } from "react";
import "./login.scss";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import LogoWhite from "../../components/svg/LogoWhite";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import InputTextField from "../../components/common/InputTextField";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import PopupMessage, {
  POPUP_VARIANT,
} from "../../components/common/PopupMessage";
import { useLocation } from "react-router-dom";

const actionCodeSettings = {
  url: `${window.location.origin}/continuelogin`,
  handleCodeInApp: true,
};

const Login = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.error) {
      setError(location?.state?.error);
    }
  }, [location]);

  const handleLogin = (e) => {
    e.preventDefault();
    setError(false);
    setEmail("");
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(async () => {
        window.localStorage.setItem("emailForSignIn", email);
        setIsEmailSent(true);
      })
      .catch((error) => {
        console.error("error: ", error);
        setError(error.code);
      });
  };

  return (
    <div className="w-full h-full relative bg-white flex flex-row items-start justify-start tracking-[normal] [row-gap:20px] text-center text-base text-text-marine-blue font-body-1 mq1125:flex-wrap">
      <div className="h-full flex-1 bg-ui-light-grey flex flex-col items-center justify-start  box-border gap-[98px] xl:min-w-[530px] max-w-full mq750:min-w-full mq450:gap-[49px_98px] mq450:box-border mq1050:box-border overflow-auto px-[16px]">
        <form onSubmit={(e) => handleLogin(e)} className="m-auto">
          <div className="xl:w-[416px] m-auto overflow-x-auto flex flex-col items-start justify-start gap-[10px] max-w-full pb-[26px] text-left text-xl text-text-marine-blue font-body-1">
            <div className="text-center w-full flex flex-row items-start justify-start py-0 pr-[9px] pl-1 box-border text-21xl">
              <Typography
                variant={TYPOGRAPHY_VARIANT.HEADING_2}
                className="text-[#323582]"
              >
                Login to the Platform
              </Typography>
            </div>
            <InputTextField
              className="relative leading-[16px] inline-block min-w-[83px]"
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={<span className="text-brand-marine-blue">Email</span>}
            />
          </div>

          <Button type="submit" variant={BUTTON_VARIANT.PRIMARY}>
            Send Magic Link
          </Button>
          <div className="xl:w-[416px] m-auto mt-5">
            {isEmailSent && (
              <PopupMessage variant={POPUP_VARIANT.SUCCESS} className="mt-1">
                Magic link is sent to yout email, please check your inbox.
              </PopupMessage>
            )}
          </div>
          <div className="xl:w-[416px] m-auto mt-5">
            {error && (
              <PopupMessage variant={POPUP_VARIANT.WARNING} className="mt-1">
                {error}
              </PopupMessage>
            )}
          </div>
        </form>
      </div>

      {/* <Logo /> */}
      <div className="animation w-[624px] h-full overflow-hidden login items-start justify-start fpt-[370px] px-20 cpb-[374px] box-border relative min-w-[624px] max-w-full text-left text-29xl text-white font-body-1">
        <div className="login-gradient"></div>
        <div className="m-auto flex flex-col gap-[45px] z-[1]">
          <div className="min-w-[624px] relative bg-text-marine-blue hidden max-w-full z-[0]" />

          <div className="absolute top-[80px] w-full left-0 right-0 flex justify-center">
            <LogoWhite className="subtract-icon" />
          </div>
          <div>
            <span className="text-[40px] leading-[60px] text-white">
              Discover how PryvX can revolutionize your data collaboration
              efforts.
            </span>
          </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
