import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const ShowTable = ({ tableData = [[]], subHeaderRow = {} }) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-root": {
      padding: "8px",
    },
  }));
  const StyledBlueTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-root": {
      backgroundColor: "#323582",
      padding: "12px",
      color: "white",
      fontWeight: "300 !important",
      fontSize: "15px !important",
    },
  }));
  const StyledTableSubCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-root": {
      backgroundColor: "#323582",
      padding: "0 12px",
      color: "white",
      fontWeight: "300 !important",
      fontSize: "10px !important",
      textTransform: "uppercase",
    },
  }));
  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
      <Table>
        <TableHead>
          {Object.keys(tableData?.[0])?.map((value) => {
            return (
              <StyledBlueTableCell key={value}>{value}</StyledBlueTableCell>
            );
          })}
        </TableHead>
        {subHeaderRow && (
          <TableHead>
            {Object.keys(subHeaderRow)?.map((value) => {
              return (
                <StyledTableSubCell key={value}>
                  {subHeaderRow[value]}
                </StyledTableSubCell>
              );
            })}
          </TableHead>
        )}
        <TableBody>
          {tableData.map((row, index) => (
            <StyledTableRow key={index}>
              {Object.values(row)?.map((value, idx) => (
                <StyledTableCell key={idx}>{value}</StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShowTable;
