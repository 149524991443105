import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export const remoteGetCleanroom = async (cleanRoomId, snackbarDispatch) => {
  try {
    const cleanRoomRef = doc(db, "clean_room", cleanRoomId);
    const cleanRoomSnapshot = await getDoc(cleanRoomRef);
    if (cleanRoomSnapshot.exists()) {
      return cleanRoomSnapshot.data();
    } else {
      snackbarDispatch &&
        snackbarDispatch({
          type: "SHOW_ERROR_SNACKBAR",
          payload: "Cleanroom not found with id " + cleanRoomId,
        });
      return {};
    }
  } catch (error) {
    snackbarDispatch &&
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while fetching Clean Room.",
      });
    console.error("Error fetching dataset:", error);
    return {};
  }
};
