import { useFieldArray } from "react-hook-form";
import Button, { BUTTON_VARIANT } from "../../../../components/common/Button";
import InputTextField from "../../../../components/common/InputTextField";
import SelectField, {
  LABEL_TYPE,
} from "../../../../components/common/SelectField";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import Close from "../../../../components/svg/Close";

const WHERE_CLAUSE = ["=", "<", ">"];

export const INITIAL_CONDITION_FIELD_VALUES = {
  column: "",
  where: "",
  fieldValue: "",
};

const ConditionsTaskForm = ({
  control,
  register,
  errors,
  allColumnOptions,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "condition",
  });

  const addConditionIdRow = () => {
    append(INITIAL_CONDITION_FIELD_VALUES);
  };
  const removeConditionIdRow = (ind) => {
    remove(ind);
  };

  const whereClauseOption = WHERE_CLAUSE.map((columnName) => {
    return { value: columnName, label: columnName };
  });
  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <div className="flex mt-3 gap-5">
        <div className="flex-1">
          <Typography className="flex-1" variant={TYPOGRAPHY_VARIANT.HEADING_4}>
            Conditions
          </Typography>
        </div>
        <div className="w-[7rem]">
          <Typography
            className="w-[7rem]"
            variant={TYPOGRAPHY_VARIANT.HEADING_4}
          >
            WHERE
          </Typography>
        </div>
        <div className="flex-1">
          <Typography className="flex-1" variant={TYPOGRAPHY_VARIANT.HEADING_4}>
            Field Value
          </Typography>
        </div>
        <div className="w-[4rem]" />
      </div>

      <div>
        {fields.map((field, ind) => (
          <div key={field.id} className="flex mt-3 gap-5">
            <div className="flex-1">
              <SelectField
                control={control}
                labelType={LABEL_TYPE.OUTLINED}
                options={allColumnOptions}
                placeholder="Choose Column"
                innerRef={register(`condition.${ind}.column`).ref}
                {...register(`condition.${ind}.column`, {
                  required: "Column is required.",
                })}
                errorMessage={errors?.condition?.[ind]?.column?.message}
                isError={!!errors?.condition?.[ind]?.column?.message}
              />
            </div>
            <div className="w-[7rem]">
              <SelectField
                control={control}
                labelType={LABEL_TYPE.OUTLINED}
                options={whereClauseOption}
                placeholder="Clause"
                innerRef={register(`condition.${ind}.where`).ref}
                {...register(`condition.${ind}.where`, {
                  required: "Where is required.",
                })}
                errorMessage={errors?.condition?.[ind]?.where?.message}
                isError={!!errors?.condition?.[ind]?.where?.message}
              />
            </div>
            <div className="flex-1">
              <InputTextField
                labelType={LABEL_TYPE.OUTLINED}
                placeholder="Enter Field Value"
                type="text"
                innerRef={register(`condition.${ind}.fieldValue`).ref}
                {...register(`condition.${ind}.fieldValue`, {
                  required: "Field Value is required.",
                })}
                errorMessage={errors?.condition?.[ind]?.fieldValue?.message}
                isError={!!errors?.condition?.[ind]?.fieldValue?.message}
              />
            </div>
            <div
              className="pt-4 px-5"
              onClick={() => removeConditionIdRow(ind)}
            >
              <Close
                height="16"
                width="16"
                className="fill-ui-grey-50 hover:fill-brand-marine-blue"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="w-[15rem]">
        <Button
          onClick={addConditionIdRow}
          type={"button"}
          variant={BUTTON_VARIANT.PRIMARY}
          className="mt-3"
        >
          ADD FUNCTION
        </Button>
      </div>
    </div>
  );
};

export default ConditionsTaskForm;
