import React, { useState } from "react";
import "./sidebar.scss";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import LogoWhite from "../svg/LogoWhite";
import DashboardFill from "../svg/DashboardFill";
import DatasetFill from "../svg/DatasetFill";
import OtherHosuesFill from "../svg/OtherHosuesFill";
import LogoutFill from "./LogoutFill";
import NavigateBeforeFill from "../svg/NavigateBeforeFill";
import LogoCompressed from "../svg/LogoCompressed";
import DefaultProfilePic from "../../components/svg/DefaultProfilePic";
import ApiIcon from "../svg/ApiIcon";
import BetaIcon from "../svg/BetaIcon";
import QuickStartDrawer from "../common/QuickStartDrawer";
import LightBulb from "../svg/LightBulb";
import CRIconFill from "../svg/CRIconFill";

const Sidebar = () => {
  const navitage = useNavigate();
  const { dispatch, currentUser } = useContext(AuthContext);
  const { userDetails } = currentUser;
  const { profileUrl, firstName, showModelHub } = userDetails;
  const currentPath = window.location.pathname;
  const currentPathRoot = currentPath.split("/")[1];
  const [isCompressed, setIsCompressed] = useState(false);
  const [showQuickStartDrawer, setShowQuickStartDrawer] = useState(false);

  const handleToggle = () => {
    setIsCompressed(!isCompressed);
  };

  const openQuickStart = () => {
    setShowQuickStartDrawer(true);
  };
  return (
    <div
      className={
        !isCompressed
          ? "sidebar-tailwind ease-in duration-500 flex justify-between flex-col"
          : "flex justify-between flex-col ease-in duration-500 sidebar-compressed"
      }
    >
      <div className="top">
        <div className="pb-[40px] flex justify-center">
          {!isCompressed ? (
            <Link to="/">
              <LogoWhite />
              {/* <div className={"ml-[70px] items-center mt-[10px]"}>
                {<BetaIcon />}
              </div> */}
            </Link>
          ) : (
            <Link to="/">
              <LogoCompressed className="ml-[5px]" />
              {/* <div className={"items-center mt-[10px]"}>{<BetaIcon />}</div> */}
            </Link>
          )}
        </div>
        <ul>
          {/* <Link to="/">
            <li
              className={
                currentPathRoot === ""
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <DashboardFill />
              {!isCompressed && <span>Dashboard</span>}
            </li>
          </Link> */}

          <Link to="/cleanroom">
            <li
              className={
                currentPathRoot === "cleanroom"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <CRIconFill />
              {!isCompressed && <span>Clean Room</span>}
            </li>
          </Link>

          {/* <Link to="/datasets">
            <li
              className={
                currentPathRoot === "datasets"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <DatasetFill />
              {!isCompressed && <span>Datasets</span>}
            </li>
          </Link>

          <Link to="/projects">
            <li
              className={
                currentPathRoot === "projects"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <OtherHosuesFill />
              {!isCompressed && <span>Projects</span>}
            </li>
          </Link> */}

          {/* <Link to="/apis">
            <li
              className={
                currentPathRoot === "apis"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              <ApiIcon />
              {!isCompressed && <span>APIs</span>}
            </li>

          </Link> */}

          {showModelHub && (
            <Link to="/models">
              <li
                className={
                  currentPathRoot === "models"
                    ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                    : "inline-flex gap-[16px] items-center"
                }
              >
                <ApiIcon />
                {!isCompressed && <span>Models</span>}
              </li>
            </Link>
          )}
        </ul>
      </div>

      <div className="bottom">
        <ul>
          <Link to="/profile">
            <li
              className={
                currentPathRoot === "profile"
                  ? "active bg-[#569EF8] rounded-[4px] inline-flex gap-[16px] items-center"
                  : "inline-flex gap-[16px] items-center"
              }
            >
              {!!profileUrl ? (
                <img
                  src={profileUrl}
                  alt=""
                  className="w-[40px] min-w-[40px] h-[40px] rounded-full"
                />
              ) : (
                <span className="bg-[#dddddd] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                  <DefaultProfilePic height="20px" width="20px" />
                </span>
              )}
              {!isCompressed && <span>{firstName}</span>}
            </li>
          </Link>

          <Link to={"#"} onClick={() => openQuickStart()}>
            <li className="inline-flex gap-[16px] items-center">
              <LightBulb fill="white" />
              {!isCompressed && <span>Quick Start</span>}
            </li>
          </Link>
          {/* <li className="inline-flex gap-[16px] items-center">
            <SettingsFill />
            {!isCompressed && <span>Settings</span>}
          </li> */}

          <li className="flex gap-[16px] items-center">
            <div
              className="flex gap-[16px] items-center"
              onClick={async () => {
                signOut(auth);
                dispatch({ type: "LOGOUT" });
                navitage("/login");
              }}
            >
              <LogoutFill className="cursor-pointer" />
              {!isCompressed && <span className="cursor-pointer">Logout</span>}
            </div>
          </li>
        </ul>
      </div>

      <button
        onClick={handleToggle}
        className="absolute right-[-23px] top-[55px] bg-[#1e1e4c] py-[8px] pr-[4px] pl-[4px] text-text-marine-blue rounded-md"
      >
        {!isCompressed ? (
          <NavigateBeforeFill />
        ) : (
          <NavigateBeforeFill className="rotate-180" />
        )}
      </button>
      <QuickStartDrawer
        show={showQuickStartDrawer}
        hideDrawer={() => setShowQuickStartDrawer(false)}
        isSidebarCompressed={!isCompressed}
      />
    </div>
  );
};

export default Sidebar;
