import { DotIcon } from "../svg/DotIcon";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";

const QuickStartContent = ({type}) => {
  if (type === "Project") {
    return (
      <div>
        <div className="mt-[30px] ml-[10px]">
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
            Follow the Steps to Get Started with Projects
          </Typography>
        </div>
        <div className="border-2 mt-[30px] ml-[10px] mr-[10px] rounded-lg">
          <div className="mt-[30px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
              1. Register Dataset
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              Register the test dataset you would like to collaborate on.
            </Typography>
          </div>
          <div className="mt-[30px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
              2. Create Project
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              Create a new federated project.
            </Typography>
          </div>
          <div className="mt-[10px] ml-[28px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <div className="flex items-center">
                <DotIcon size={6} /> Select your task.
              </div>
            </Typography>
          </div>
          <div className="ml-[28px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <div className="flex items-center">
                <DotIcon size={6} />
                Invite collaborators to join the project.
              </div>
            </Typography>
          </div>
          <div className="mt-[30px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
              3. Download Global Model
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              Once all collaborators have finished training the model, you can
              download the global model and start using it.
            </Typography>
          </div>
        </div>
      </div>
    );
  } else if (type === "Clean Room") {
    return (
      <div>
        <div className="mt-[30px] ml-[10px]">
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
            Create a Data clean room environment.
          </Typography>
        </div>
        <div className="border-2 mt-[30px] ml-[10px] mr-[10px] rounded-lg">
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              1. Invite your data collaborators.
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              2. Add your dataset.
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              <div className="flex items-center">
                3. Perform a joint analysis on your dataset(s) along with your
                collaborators' using the assigned task.
              </div>
            </Typography>
          </div>
          <div className="mt-[10px] ml-[20px]">
            <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
              4. Access query output results.
            </Typography>
          </div>
        </div>
      </div>
    );
  } else {
    return <>Select Option</>;
  }
};

export default QuickStartContent;
