import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../components/common/Typography";

const dateOptions = { day: "2-digit", month: "long", year: "numeric" };

const CleanRoomDetails = ({ values }) => {
  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5 bg-[#f7f7f7]">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row">
          <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
            Room Name
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {values.name}
          </Typography>
        </div>
        <div className="flex flex-row mt-5">
          <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
            Room Description
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {values.description}
          </Typography>
        </div>
        <div className="flex flex-row mt-5">
          <Typography variant={TYPOGRAPHY_VARIANT.LABEL_BOLD}>
            Date Expires
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            {new Date(values?.expireDate).toLocaleDateString(
              "en-GB",
              dateOptions
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CleanRoomDetails;
