import { useCallback, useState } from "react";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import Close from "../../../../components/svg/Close";
import InputWarningIcon from "../../../../components/svg/InputWarningIcon";
import UploadFill from "../../../../components/svg/UploadFill";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { read as xlsxRead, utils as xlsxUtils } from "xlsx";
import { Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const UploadFile = ({ fileValues, setFileValues }) => {
  const [error, setError] = useState(null);

  const parseCsvData = (csvData) => {
    const parsedData = Papa.parse(csvData, { header: false });
    return parsedData.data;
  };

  const handleFileChange = useCallback(
    async (data) => {
      const file = data.target.files[0];
      if (file) {
        const fileNameWithoutExt = file.name.split(".").slice(0, -1).join(".");
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileResult = e.target.result;

          const fileExtention = file.name.split(".").slice(-1).toString();
          // Parse the CSV data and set the tableData state
          if (fileExtention === "csv") {
            setFileValues({ sample: parseCsvData(fileResult), csvFile: file });
          } else if (fileExtention === "xlsx") {
            const xlsxToCsv = async () => {
              const ab = await file.arrayBuffer();
              const wb = xlsxRead(ab);
              var res = "";
              wb.SheetNames.forEach((n) => {
                const ws = wb.Sheets[n];
                res += xlsxUtils.sheet_to_csv(ws) + "\n\n";
              });
              const csvBlob = new Blob([res], { type: "text/csv" });
              const csvFile = new File([csvBlob], fileNameWithoutExt + ".csv", {
                type: "text/csv",
              });
              setFileValues((prevState) => ({
                ...prevState,
                csvFile: csvFile,
              }));
            };
            xlsxToCsv();
          }
        };
        reader.readAsText(file);
      }
    },
    [setFileValues]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const allowedTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      const maxSize = 10 * 1024 * 1024;

      if (acceptedFiles.length === 0) {
        setError("No file selected. Please select a file.");
        return;
      }

      const file = acceptedFiles[0];

      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please import a valid dataset file.");
        return;
      }

      if (file.size > maxSize) {
        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        setError(
          `File size exceeds the limit of 10MB. Current size: ${fileSizeMB}MB`
        );
        return;
      }
      handleFileChange({ target: { files: [file] } });
      setError(null);
    },
    [handleFileChange]
  );

  const handleCloseBtn = (e) => {
    e.stopPropagation();
    setFileValues({
      sample: [],
      csvFile: null,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Upload File
      </Typography>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
        Provide the data template you wish to share with your selected audience
        or segment.
      </Typography>
      <div className="relative w-full max-w-2xl max-h-full flex h-full mb-6">
        <div className="bg-white w-[528px]">
          <div className="flex flex-col gap-[24px] relative w-full">
            <div
              {...getRootProps()}
              className={`border-[2px] border-dashed py-[64px] px-[40px] rounded-md flex flex-col justify-center gap-[8px] mt-6 mb-3 ${
                isDragActive
                  ? "border-brand-sea-blue bg-[#a0ccfb3d]"
                  : fileValues.csvFile
                  ? "border-[#1c771c] bg-[#a0e3a83d]"
                  : "border-black"
              }`}
            >
              {fileValues.csvFile && (
                <p className="border-2 rounded-lg p-2 bg-white w-fit">
                  {fileValues?.csvFile?.name}
                  <button
                    type="button"
                    onClick={handleCloseBtn}
                    className="cursor-pointer ml-2"
                  >
                    <CloseIcon fontSize="20px" />
                  </button>
                </p>
              )}
              <UploadFill className="m-auto" />
              <div className="text-center">
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>
                    Drag and Drop file here or{" "}
                    <span for="upload-file" className="cursor-pointer">
                      <u>Choose Dataset</u>
                    </span>
                  </p>
                )}
                <b>
                  <input
                    {...getInputProps()}
                    className="hidden"
                    type="file"
                    id="upload-file"
                    name="uploadedFile"
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                </b>
              </div>
            </div>
            <div className="flex justify-between mt-[-5%] ">
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_2}
                className="text-[#999999] text-[17px]"
              >
                Supported formats: CSV, XLSX
              </Typography>
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_2}
                className="text-right text-[#999999] text-[17px]"
              >
                Maximum size: 10MB
              </Typography>
            </div>
          </div>
          {error ? (
            <>
              <Typography
                variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
                className=" flex gap-[8px] text-[#D2333A] bg-[] border border-[#D2333A]  mt-[-6%] w-[100%] pl-[10px] py-[4px] text-[15px]"
              >
                <span className="">{<InputWarningIcon />}</span>
                {error}
                <h4
                  onClick={() => setError(null)}
                  className="text-[#DDDDDD] pl-[40px] text-[25px] mt-[6.5px]"
                >
                  <Close />
                </h4>
              </Typography>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Alert variant="outlined" severity="warning" className="w-[528px]">
        Note: Please make sure your remove or hash all PII info in your dataset
        before uploading
      </Alert>
    </div>
  );
};

export default UploadFile;
