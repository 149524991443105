import SelectField, {
  LABEL_TYPE,
} from "../../../../components/common/SelectField";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import { EqualIcon } from "../../../../components/svg/EqualIcon";
import { useMemo } from "react";
import InputRadioBtn from "../../../../components/common/InputRadioBtn";

const JOIN_OPTIONS = ["Inner"]; // , "Outer"

const JoinTaskForm = ({ control, register, errors, datasetsData, watch }) => {
  const [seletedtable1Id, seletedtable2Id] = watch(["table1.id", "table2.id"]);

  const table1ColumnOptions = useMemo(() => {
    const option = datasetsData
      .find((dataset) => dataset.id === seletedtable1Id)
      ?.datasetColumns?.map((columnName) => {
        return { value: columnName, label: columnName };
      });

    return option
      ? [{ value: undefined, label: "Choose Column from Table 1" }, ...option]
      : [];
  }, [seletedtable1Id, datasetsData]);

  const table2ColumnOptions = useMemo(() => {
    const option = datasetsData
      .find((dataset) => dataset.id === seletedtable2Id)
      ?.datasetColumns?.map((columnName) => {
        return { value: columnName, label: columnName };
      });
    return option
      ? [{ value: undefined, label: "Choose Column from Table 2" }, ...option]
      : [];
  }, [seletedtable2Id, datasetsData]);

  const joinConditionOptions = JOIN_OPTIONS?.map((columnName) => {
    return { value: columnName, label: columnName };
  });
  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>Join ID</Typography>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
        Select the specific column for the Join ID (the system shows a list of
        Join IDs chosen during the data upload).
      </Typography>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1} className="mb-3">
        Join Condition
      </Typography>
      <InputRadioBtn
        control={control}
        options={joinConditionOptions}
        innerRef={register(`joinCondition`).ref}
        {...register(`joinCondition`, {
          required: "Join Condition is required.",
        })}
        errorMessage={errors?.joinCondition?.message}
        isError={!!errors?.joinCondition?.message}
      />
      <div>
        <div className="flex">
          <div className="flex-1 mt-2">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
              className="mb-3"
            >
              Table 1
            </Typography>
            <SelectField
              control={control}
              labelType={LABEL_TYPE.OUTLINED}
              options={table1ColumnOptions}
              placeholder="Choose Column from Table 1"
              innerRef={register(`join.table1Column`).ref}
              {...register(`join.table1Column`, {
                required: "Column in table 1 is required.",
              })}
              errorMessage={errors?.join?.table1Column?.message}
              isError={!!errors?.join?.table1Column?.message}
            />
          </div>
          <div className="pt-16 px-5">
            <EqualIcon />
          </div>
          <div className="flex-1 mt-2">
            <Typography
              variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}
              className="mb-3"
            >
              Table 2
            </Typography>
            <SelectField
              control={control}
              labelType={LABEL_TYPE.OUTLINED}
              options={table2ColumnOptions}
              placeholder="Choose Column from Table 2"
              innerRef={register(`join.table2Column`).ref}
              {...register(`join.table2Column`, {
                required: "Column in table 2 is required.",
              })}
              errorMessage={errors?.join?.table2Column?.message}
              isError={!!errors?.join?.table2Column?.message}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinTaskForm;
