import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import ShowTable from "./components/ShowTable";
import NotFound from "../404notfound/NotFound";
import ArrowBack from "../../components/svg/ArrowBack";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import OverlayLoader from "../../components/common/OverlayLoader";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { SnackbarContext } from "../../context/SnackbarContext";

const ViewCleanRoomData = () => {
  const { id, dataId } = useParams();

  const [tableData, setTableData] = useState([{}]);
  const [datasetData, setDatasetData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const getOutputTable = useCallback(async () => {
    try {
      setIsLoading(true);
      const cleanRoomRef = doc(db, "clean_room", id);
      const dataColRef = doc(cleanRoomRef, "datasets", dataId);
      const cleanRoomDataSnapshot = await getDoc(dataColRef);
      if (cleanRoomDataSnapshot.exists()) {
        setDatasetData(cleanRoomDataSnapshot.data());
        const table = {};
        cleanRoomDataSnapshot.data().datasetColumns.forEach((col) => {
          table[col] = "*****";
        });
        setTableData([table, table, table, table, table]);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("error", err);
      setIsLoading(false);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while RFetching Dataset details.",
      });
    }
  }, [dataId, id, snackbarDispatch]);

  useEffect(() => {
    if (id && dataId) {
      getOutputTable();
    }
  }, [id, dataId, getOutputTable]);

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (!id || !dataId) {
    return (
      <NotFound
        pageName="clean room data"
        // message={"Data is only visible to the owner of this data."}
        btnNavigatePath={`/cleanroom/${id}`}
      />
    );
  }

  return (
    <div className="p-[32px]">
      <div className="datatableTitle flex flex-wrap justify-between mb-2 gap-4">
        <div className="flex flex-col flex-wrap gap-4 mb-8 w-full">
          <div className="flex">
            <Link
              to={`/cleanroom/${id}`}
              state={{ tabName: "Data" }}
              className="flex gap-2 hover:fill-brand-marine-blue mr-5"
            >
              <ArrowBack />
            </Link>
            <Typography
              variant={TYPOGRAPHY_VARIANT.HEADING_3}
              className="content-end"
            >
              {datasetData?.datasetName ?? "Data Set"}
            </Typography>
          </div>
        </div>
        <div className="border-dark-grey border-[1px] rounded-3xl p-5 mr-[20px] w-full">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1} className="pb-4">
            <InsertDriveFileOutlinedIcon fontSize="20px" className="mr-[5px]" />
            {datasetData?.fileName}
          </Typography>
          <ShowTable
            tableData={tableData}
            // subHeaderRow={datasetData.types}
          />
        </div>
      </div>
    </div>
  );
};
export default ViewCleanRoomData;
