import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import CustomTooltip from "./CustomTooltip/CustomTooltip";
import InfoFill from "../svg/InfoFill";
import { Controller } from "react-hook-form";

export const LABEL_TYPE = {
  OUTLINED: "OUTLINED",
  STANDARD: "STANDARD",
};

const InputRadioBtn = ({
  className = "",
  label = "",
  endAdornment,
  startAdornment,
  options,
  passWordEyeIcon,
  isError = false,
  isSuccess = false,
  errorMessage = "",
  innerRef,
  labelType = LABEL_TYPE.STANDARD,
  toolTipTitle = null,
  control,
  ...props
}) => {
  return (
    <div className="relative w-full flex flex-col items-start justify-start gap-[8px] z-[1]">
      {label && (
        <div>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{label}</Typography>
        </div>
      )}
      <div className="w-full relative flex items-start">
        <Controller
          name="joinCondition"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioGroup className={className} {...field}>
              <div>
                {options?.map((option, ind) => (
                  <FormControlLabel
                    key={ind}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </div>
            </RadioGroup>
          )}
        />
        {toolTipTitle && (
          <CustomTooltip title={toolTipTitle}>
            <span>
              <InfoFill className="relative ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
            </span>
          </CustomTooltip>
        )}
      </div>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2} className="text-warning">
        {errorMessage}
      </Typography>
    </div>
  );
};
export default InputRadioBtn;
