import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../context/SnackbarContext";
import { AuthContext } from "../../context/AuthContext";
import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../../firebase";
import NotFound from "../404notfound/NotFound";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import CleanRoomDetails from "./components/CleanRoomDetails";
import ConfigurationTaskForm from "./components/taskSubForms/ConfigurationTaskForm";
import JoinTaskForm from "./components/taskSubForms/JoinTaskForm";
import ColumnSelectionTaskForm from "./components/taskSubForms/ColumnSelectionTaskForm";
import AggregateTaskForm, {
  INITIAL_AGGREGATE_FIELD_VALUES,
} from "./components/taskSubForms/AggregateTaskForm";
import ConditionsTaskForm, {
  INITIAL_CONDITION_FIELD_VALUES,
} from "./components/taskSubForms/ConditionsTaskForm";
import cleanObject from "../../utils/cleanObject";
import createSqlQuery from "../../utils/createSqlQuery";
import { remoteGetCleanroom } from "../../utils/firebase/cleanroom/remoteGetCleanroom";
import { remoteGetCleanroomAllDatasets } from "../../utils/firebase/cleanroom/remoteGetCleanroomAllDatasets";

const AddCleanRoomTask = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    description: "",
    createDate: null,
    expireDate: null,
    collaborators: [],
  });
  const [datasetsData, setDatasetsData] = useState([]);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const {
    handleSubmit: handleFormSubmit,
    formState,
    control,
    register,
    watch,
    setValue,
    resetField,
  } = useForm({
    defaultValues: {
      condition: [INITIAL_CONDITION_FIELD_VALUES],
      aggregateFun: [INITIAL_AGGREGATE_FIELD_VALUES],
    },
  });
  const { errors } = formState;

  useEffect(() => {
    if (id) {
      const promises = async () => {
        setIsLoading(true);
        const [cleanRoomData, datasets] = await Promise.all([
          remoteGetCleanroom(id, snackbarDispatch),
          remoteGetCleanroomAllDatasets(id, snackbarDispatch),
        ]);
        setValues({
          name: cleanRoomData.name || "",
          description: cleanRoomData.description || "",
          createDate: cleanRoomData.createDate || "",
          expireDate: cleanRoomData.expireDate || "",
          userId: cleanRoomData.userId || "",
          collaborators: cleanRoomData.collaborators || [],
        });

        if (
          cleanRoomData?.collaborators?.filter(
            (coll) => coll.id === currentUser.uid
          )?.[0]?.status === "Pending"
        ) {
          setIsInvitationAccepted(false);
        } else {
          setIsInvitationAccepted(true);
        }
        setDatasetsData(datasets);
        setIsLoading(false);
      };
      promises();
    }
  }, [currentUser.uid, id, snackbarDispatch]);

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  // Will remove this later after development
  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) =>
  //     console.log("value", value, "\nname", name, "\ntype", type)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const cleanData = cleanObject(data, ["description"]);
    const query = createSqlQuery(data);
    // console.log("form data: ", data, "\ncleanData", cleanData);
    try {
      const userDocRef = doc(db, "clean_room", id);
      const datasetsColRef = collection(userDocRef, "tasks");
      const taskInfo = {
        createDate: Date.now(),
        userId: user.uid,
        userEmail: user.userDetails.email,
        query: query,
        totalTransactions: 0,
        status: "",
        ...cleanData,
      };
      const res = await addDoc(datasetsColRef, taskInfo);

      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Task Added successfully.",
      });

      setIsLoading(false);
      navigate(`/cleanroom/${id}/view-task/${res.id}`);
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while adding task.",
      });
      console.error(error);
      setIsLoading(false);
    }
  };

  const submitDisabled = useMemo(() => false, []);

  const isAuthenticated = useMemo(
    () =>
      !!values.collaborators.find((col) => col?.id === user?.uid) ||
      values.userId === user?.uid,
    [values, user]
  );

  const [seletedtable1Id, seletedtable2Id] = watch(["table1.id", "table2.id"]);

  const allColumnOptions = useMemo(() => {
    const table1ColumnOptions = datasetsData
      .find((dataset) => dataset.id === seletedtable1Id)
      ?.datasetColumns?.map((columnName) => {
        return { value: `t1.${columnName}`, label: `t1.${columnName}` };
      });

    const table2ColumnOptions = datasetsData
      .find((dataset) => dataset.id === seletedtable2Id)
      ?.datasetColumns?.map((columnName) => {
        return { value: `t2.${columnName}`, label: `t2.${columnName}` };
      });
    return [...(table1ColumnOptions ?? []), ...(table2ColumnOptions ?? [])];
  }, [seletedtable1Id, seletedtable2Id, datasetsData]);

  if (isLoading) {
    return <OverlayLoader />;
  }

  if (values === null || !isAuthenticated) {
    return <NotFound pageName="clean room" />;
  }

  const isExpired = new Date().valueOf() > values?.expireDate;

  if (!isInvitationAccepted || isExpired) {
    return (
      <NotFound
        pageName="clean room"
        message={
          isExpired
            ? "Task cannot be added after cloonroom is expired."
            : "Accept Collaboration invitation to access this page."
        }
        btnNavigatePath={`/cleanroom/${id}`}
      />
    );
  }

  return (
    <div className="bg-white h-full overflow-auto">
      <form className="px-[50px]" onSubmit={handleFormSubmit(handleSubmit)}>
        <div className="flex flex-col gap-[40px]">
          <div className="bg-white py-[40px] mx-10">
            <div className="flex flex-col gap-[40px]">
              <div className="flex flex-wrap justify-between">
                <div className="flex flex-col flex-wrap gap-4 mb-4 w-full">
                  <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                    Add Task
                  </Typography>
                  <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
                    Perform specific operations to securely manage and analyze
                    data with full control.
                  </Typography>
                </div>
              </div>
              <CleanRoomDetails values={values} />
              <ConfigurationTaskForm
                control={control}
                register={register}
                errors={errors}
                datasetsData={datasetsData}
                watch={watch}
                setValue={setValue}
                currentUserId={user.uid}
                resetField={resetField}
              />
              <JoinTaskForm
                control={control}
                register={register}
                errors={errors}
                datasetsData={datasetsData}
                watch={watch}
              />
              <ColumnSelectionTaskForm
                control={control}
                register={register}
                errors={errors}
                datasetsData={datasetsData}
                watch={watch}
              />
              <AggregateTaskForm
                control={control}
                register={register}
                errors={errors}
                datasetsData={datasetsData}
                watch={watch}
                allColumnOptions={allColumnOptions}
              />
              <ConditionsTaskForm
                control={control}
                register={register}
                errors={errors}
                allColumnOptions={allColumnOptions}
              />

              <div className="flex gap-[16px] justify-end justify-self-end justify-item-end">
                <Button
                  type="button"
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={() =>
                    navigate(`/cleanroom/${id}`, { state: { tabName: "Task" } })
                  }
                  className="w-[300px]"
                >
                  CANCEL
                </Button>
                <Button
                  variant={
                    submitDisabled || errors.dataOperetor
                      ? BUTTON_VARIANT.DISABLED
                      : BUTTON_VARIANT.PRIMARY
                  }
                  type="submit"
                  className="w-[300px]"
                  disabled={submitDisabled}
                >
                  Create Task
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCleanRoomTask;
