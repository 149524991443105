import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import InfoFill from "../svg/InfoFill";
import CustomTooltip from "./CustomTooltip/CustomTooltip";
import InputWarningIcon from "../svg/InputWarningIcon";
import InputSuccessIcon from "../svg/InputSuccessIcon";
import dayjs from "dayjs";

export const LABEL_TYPE = {
  OUTLINED: "OUTLINED",
  STANDARD: "STANDARD",
};

const CustomDatePicker = ({
  label,
  labelType,
  innerRef,
  name,
  control,
  errorMessage = "",
  endAdornment,
  defaultValue = null,
  isError = false,
  isSuccess = false,
  toolTipTitle,
  infoIconOutsideContainer,
  ...props
}) => {
  return (
    <div
      className="relative w-full flex flex-col items-start justify-start gap-[8px] z-[1]"
      style={infoIconOutsideContainer ? { width: "calc(100% + 32px)" } : {}}
    >
      {labelType === LABEL_TYPE.STANDARD && (
        <div className="ml-[-4px] h-[22px] flex flex-row items-start justify-start pt-0 px-1 pb-0 box-border">
          <Typography variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}>
            {label}
          </Typography>
        </div>
      )}
      {labelType === LABEL_TYPE.OUTLINED && (
        <div className="absolute top-[-12px] bg-white ml-2 z-[2]">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{label}</Typography>
        </div>
      )}
      <div className="relative flex items-start w-[50%] md:w-[100%]">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name={name}
            control={control}
            {...props}
            defaultValue={defaultValue}
            render={({ field }) => (
              <DatePicker
                {...field}
                minDate={dayjs()}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
                sx={{ width: "100%" }}
                className={"datepicker"}
              />
            )}
          />
          <span
            className={`absolute top-[15px] flex ${
              toolTipTitle ? "right-[72px]" : "right-[26px]"
            }`}
          >
            {endAdornment && <span className="">{endAdornment}</span>}
            {isError && <span className="">{<InputWarningIcon />}</span>}
            {isSuccess && <span className="">{<InputSuccessIcon />}</span>}
          </span>
          {toolTipTitle && (
            <CustomTooltip title={toolTipTitle}>
              <span>
                <InfoFill className="relative ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
              </span>
            </CustomTooltip>
          )}
        </LocalizationProvider>
      </div>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2} className="text-warning">
        {errorMessage}
      </Typography>
    </div>
  );
};

export default CustomDatePicker;
