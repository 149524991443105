export const API_CONFIG = {
  // BASE_API_URL: "https://middleware-app-siuquavhfa-lz.a.run.app",
  // BASE_API_URL: "http://127.0.0.1:8000",
  BASE_API_URL: process.env.REACT_APP_BASE_URL,

  END_POINTS: {
    METRICS: {
      GET_METRICS_COLLECTION: (id) =>
        `get-metrics-document/metrics_collection/${id}`,
    },
    PROJECT: {
      GET_PROJECT: (id) => `get-project/${id}`,
      DOWNLAOD_MODEL: (project_id) => `download-model/${project_id}`,
    },
    MAIL: {
      SEND_MAIL: "send-mail",
    },
    MODEL: {
      GET_MODAL_SUMMARY: (id) => `get-cdr-summary/${id}`,
    },
    CLEAN_ROOM: {
      UPLOAD_DATASET: (clean_room_id) => `upload-file/${clean_room_id}`,
      EXECUTE_SQL_TASK_QUERY: (clean_room_id, task_id) =>
        `/execute-sql-query/${clean_room_id}/${task_id}`,
    },
  },
};

export const API_REQUEST_TYPE = {
  GET: "GET",
  POST: "POST",
};

const api = async (requestType, endpoint, body) => {
  const userData = localStorage.getItem("user");
  const accessToken = JSON.parse(userData).stsTokenManager.accessToken;

  if (!accessToken) {
    //Write logout login
    throw new Error("Access token not found in user data");
  } else if (!Object.values(API_REQUEST_TYPE).includes(requestType)) {
    throw new Error("Request Type is not defined");
  } else if (requestType === API_REQUEST_TYPE.GET && !!body) {
    throw new Error("GET request dont parse body in API.");
  } else if (requestType === API_REQUEST_TYPE.POST && !body) {
    throw new Error("POST request requires body in API.");
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const fileUploadHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const isFileUpload = endpoint.split("/")?.[0] === "upload-file";
  const apiHeaders = isFileUpload ? fileUploadHeaders : headers;
  const apiBody = isFileUpload ? body : JSON.stringify(body);

  return fetch(`${API_CONFIG.BASE_API_URL}/${endpoint}`, {
    method: requestType,
    headers: apiHeaders,
    body: apiBody,
  })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export default api;
