import React, { useEffect, useState } from "react";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import DeleteBinIcon from "../../components/svg/DeleteBinIcon";
import InputTextField, {
  INPUT_VARIENT,
  LABEL_TYPE,
} from "../../components/common/InputTextField";
import { auth, db, storage } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import OverlayLoader from "../../components/common/OverlayLoader";
import InputTextArea from "../../components/common/InputTextArea";
import ProfileImageEditModal from "../../components/modals/ProfileImageEditModal";
import DefaultProfilePic from "../../components/svg/DefaultProfilePic";
import EditPenIcon from "../../components/svg/EditPenIcon";
import DeleteProfileModal from "../../components/modals/DeleteProfileModal";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { convertBase64ToFile } from "../../utils/helper";
import { updateProfile } from "firebase/auth/cordova";
import { SnackbarContext } from "../../context/SnackbarContext";
import CodeFill from "../../components/svg/CodeFill";
import SideDrawer from "../../components/common/SideDrawer";
import { UserAccessKeyDrawerBody } from "../projects/components/drawerBody";
// import Select from "react-select";
// import GreenIcon from "../../components/svg/GreenIcon";
// import YellowIcon from "../../components/svg/YellowIcon";
// import RedIcon from "../../components/svg/RedIcon";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenProfileImage, setIsOpenProfileImage] = useState(false);
  const [profileImageSaved, setProfileImageSaved] = useState(false);
  const [showUserAccessKeyDrawer, setShowUserAccessKeyDrawer] = useState(false);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit: handleFormSubmit,
    setValue: setFormValue,
    formState,
  } = useForm();
  const { errors } = formState;

  const { currentUser, dispatch } = useContext(AuthContext);
  const [firstName, setfirstName] = useState("");
  const [openDeleteProfile, setDeleteProfile] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("Available");
  const { uid: currentUserId, userDetails } = currentUser;
  const { profileUrl } = userDetails;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const snapShot = await getDoc(doc(db, "users", currentUserId));
        const idValue = snapShot.data();
        setFormValue("firstName", idValue.firstName ?? "");
        setFormValue("lastName", idValue.lastName ?? "");
        setFormValue("email", idValue.email ?? "");
        setFormValue("company", idValue.company ?? "");
        setFormValue("role", idValue.role ?? "");
        setFormValue("textfield", idValue.textfield ?? "");
        setfirstName(idValue.firstName ?? "");
        setProfileImageSaved(idValue.profileUrl ?? "");
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [auth]);

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      if (profileImageSaved.slice(0, 5) !== "https") {
        const storageRef = ref(storage, `profile-images/${currentUserId}`);
        const blobImage = convertBase64ToFile(profileImageSaved);
        const uploadTaskSnapshot = await uploadBytesResumable(
          storageRef,
          blobImage
        );
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        data.profileUrl = downloadURL;
      } else {
        data.profileUrl = profileUrl;
      }
      await updateDoc(doc(db, "users", currentUserId), data);
      dispatch({
        type: "UPDATE_PROFILE",
        payload: { ...currentUser.userDetails, ...data },
      });
      updateProfile(auth.currentUser, {
        displayName: data.firstName,
        photoURL: data.profileUrl,
      });
      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Profile updated successfully.",
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while updating profile.",
      });
      setIsLoading(false);
    }
  };

  // const options = [
  //   { label: "Available", icon: <GreenIcon />, value: "Availlable" },
  //   { label: "Away", icon: <YellowIcon />, value: "Away" },
  //   {
  //     label: "Do not Disturb",
  //     icon: <RedIcon />,
  //     value: "Do not Disturb",
  //   },
  // ];

  // const handleChange = (e) => {
  //   setSelectedOption(e);
  // };

  // const colourStyles = {
  //   control: (styles, { isFocused, isSelected }) => ({
  //     ...styles,
  //     borderRadius: "12px",
  //     padding: "12px",
  //     borderColor: "#3138A8",
  //     backgroundColor: "rgb(224 242 254)",
  //     ...(isFocused || isSelected ? { borderColor: "#3138A8" } : null),
  //     "&:hover": {
  //       borderColor: "#3138A8",
  //     },
  //   }),
  //   indicatorSeparator: (provided, state) => ({
  //     ...provided,
  //     display: "none",
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     console.log({ data, isDisabled, isFocused, isSelected });
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#F2F2F2" : null,
  //       color: "#333333",
  //       padding: "20px",
  //       "&:active": {
  //         backgroundColor: "#F2F2F2",
  //       },
  //     };
  //   },
  // };

  return (
    <div className="p-8">
      <div className="">
        <div className="flex flex-wrap items-center gap-5 justify-between w-full border-b border-[#999999] pb-14 pt-5">
          <div className="flex flex-wrap items-center gap-2">
            {!!profileUrl ? (
              <img
                src={profileUrl}
                alt="Cropped"
                className="rounded-full"
                style={{
                  maxWidth: "100%",
                  height: "50px",
                  width: "50px",
                }}
              />
            ) : (
              <span className="bg-[#dddddd] w-[64px] min-w-[64px] h-[64px] flex items-center justify-center rounded-full">
                <DefaultProfilePic height="32px" width="32px" />
              </span>
            )}
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
              {firstName}
            </Typography>
          </div>
          {/* <div className="mr-auto ml-[10px] lg:w-[15%] md:w-[100%] border-rounded-lg">
            <Select
              value={selectedOption}
              options={options}
              onChange={handleChange}
              styles={colourStyles}
              isSearchable={false}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {e.icon}
                  <span style={{ marginLeft: 5 }}>{e.label}</span>
                </div>
              )}
              menuPortalTarget={document.body}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "100%",
                  },
                },
              }}
            />
          </div> */}
          <div className="flex gap-2 items-center">
            <span
              onClick={() => setDeleteProfile(true)}
              className="flex cursor-pointer drop-shadow-md hover:bg-gray-200 items-center justify-center bg-white rounded-full w-[48px] min-w-[48px] h-[48px]"
            >
              <DeleteBinIcon />
            </span>
            <span>
              <Button
                variant={BUTTON_VARIANT.SECONDARY}
                className="flex items-center"
                buttonClassName="flex items-center gap-2"
                onClick={() => setShowUserAccessKeyDrawer(true)}
              >
                <CodeFill />
                Access Key
              </Button>
            </span>
          </div>
        </div>
        <form onSubmit={handleFormSubmit(handleSubmit)}>
          <div class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 gap-4 pt-14">
            <div>
              <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                PROFILE IMAGE
              </Typography>
              <div className="pt-5 relative">
                <span className="bg-[#dddddd] w-[290px] min-w-[290px] h-[290px] flex items-center justify-center rounded-full relative">
                  {profileImageSaved ? (
                    <>
                      <img
                        src={profileImageSaved}
                        alt="Cropped"
                        className="rounded-full"
                        width="290px"
                        height="290px"
                      />
                      <span
                        className="bg-white w-[44px]  min-w-[44px] h-[44px] flex items-center justify-center rounded-full shadow cursor-pointer absolute bottom-0 right-[15%]"
                        onClick={() => setIsOpenProfileImage(true)}
                      >
                        <EditPenIcon />
                      </span>
                    </>
                  ) : (
                    <div>
                      <DefaultProfilePic />
                      <span
                        className="bg-white w-[44px] min-w-[44px] h-[44px] flex items-center justify-center rounded-full shadow cursor-pointer absolute bottom-0 right-[15%]"
                        onClick={() => setIsOpenProfileImage(true)}
                      >
                        <EditPenIcon />
                      </span>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div>
              <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                PERSONAL DETAILS
              </Typography>
              <div className="pt-[32px] flex flex-col gap-6">
                <div>
                  <InputTextField
                    className="relative leading-[16px] inline-block min-w-[83px]"
                    type="text"
                    label="First Name"
                    variant={INPUT_VARIENT.SHADED}
                    labelType={LABEL_TYPE.OUTLINED}
                    errorMessage={errors?.firstName?.message}
                    isError={!!errors?.firstName?.message}
                    {...register("firstName", {
                      required: "First Name field is required.",
                    })}
                    innerRef={register("firstName").ref}
                  />
                </div>
                <div>
                  <InputTextField
                    className=""
                    type="text"
                    label="Last Name"
                    variant={INPUT_VARIENT.SHADED}
                    labelType={LABEL_TYPE.OUTLINED}
                    errorMessage={errors?.lastName?.message}
                    isError={!!errors?.lastName?.message}
                    {...register("lastName")}
                    innerRef={register("lastName").ref}
                  />
                </div>
                <div>
                  <InputTextField
                    className=""
                    type="text"
                    label="Email Address"
                    disabled
                    variant={INPUT_VARIENT.SHADED}
                    labelType={LABEL_TYPE.OUTLINED}
                    errorMessage={errors?.email?.message}
                    isError={!!errors?.email?.message}
                    {...register("email", {
                      required: "Email field is required.",
                    })}
                    innerRef={register("email").ref}
                  />
                </div>
              </div>
            </div>
            <div>
              <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                COMPANY DETAILS
              </Typography>
              <div className="pt-[32px] flex flex-col gap-6">
                <div>
                  <InputTextField
                    className="relative leading-[16px] inline-block min-w-[83px]"
                    type="text"
                    label="Company"
                    variant={INPUT_VARIENT.SHADED}
                    labelType={LABEL_TYPE.OUTLINED}
                    errorMessage={errors?.company?.message}
                    isError={!!errors?.company?.message}
                    {...register("company", {
                      required: "Company field is required.",
                    })}
                    innerRef={register("company").ref}
                  />
                </div>
                <div>
                  <InputTextField
                    className="relative leading-[16px] inline-block min-w-[83px]"
                    type="text"
                    label="Role"
                    variant={INPUT_VARIENT.SHADED}
                    labelType={LABEL_TYPE.OUTLINED}
                    errorMessage={errors?.role?.message}
                    isError={!!errors?.role?.message}
                    {...register("role")}
                    innerRef={register("role").ref}
                  />
                </div>
              </div>
              <div className="pt-6">
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  OTHER INFORMATION
                </Typography>
                <div className="pt-[32px] flex flex-col gap-6">
                  <div>
                    <InputTextArea
                      className="relative leading-[16px] inline-block min-w-[83px]"
                      type="text"
                      label="Text Field"
                      variant={INPUT_VARIENT.SHADED}
                      labelType={LABEL_TYPE.OUTLINED}
                      errorMessage={errors?.textfield?.message}
                      isError={!!errors?.textfield?.message}
                      {...register("textfield")}
                      innerRef={register("textfield").ref}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-[16px] pt-[100px]">
                <Button
                  variant={
                    errors.datasetName || errors.desc
                      ? BUTTON_VARIANT.DISABLED
                      : BUTTON_VARIANT.PRIMARY
                  }
                  type={"submit"}
                >
                  SAVE DETAILS
                </Button>
              </div>
            </div>
          </div>
        </form>
        {isLoading && <OverlayLoader />}
        {openDeleteProfile && (
          <DeleteProfileModal
            handleClose={() => setDeleteProfile(false)}
            setLoading={setIsLoading}
            profileImage={profileImageSaved}
            firstName={firstName}
            setDeleteProfile={setDeleteProfile}
          />
        )}
        {isOpenProfileImage && (
          <ProfileImageEditModal
            handleClose={() => setIsOpenProfileImage(false)}
            setLoading={setIsLoading}
            setProfileImageSaved={setProfileImageSaved}
          />
        )}
        <SideDrawer
          show={showUserAccessKeyDrawer}
          hideDrawer={() => setShowUserAccessKeyDrawer(false)}
          title="User Access Key"
          body={
            <UserAccessKeyDrawerBody
              userId={currentUserId}
            />
          }
        />
      </div>
    </div>
  );
};

export default Profile;
