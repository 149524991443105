import api, { API_CONFIG, API_REQUEST_TYPE } from "../api/api";

export const EMAIL_TEMPLATE = {
  PROJECT_INVITATION: (details) => {
    return {
      subject: "Invitation to Join PryvX Project",
      body: `<body><p>Dear ${details.toFirstName}<b></b>,</p><br /><p>You have been invited to join the <b>${details.projectName}</b> as data collaborator by <b>${details.currentUserName}</b>.</p><p> <a href=${process.env.REACT_APP_REDIRECT_URL}>Accept</a> | <a href=${process.env.REACT_APP_REDIRECT_URL}>Deny</a></p><br /><div>The request is valid for 7 days.</div><img src="https://firebasestorage.googleapis.com/v0/b/pryvx-base.appspot.com/o/assets%2FPryvx-email-footer-logo.png?alt=media&token=f00555d6-dd65-4cc3-8c81-f41bcdaa150a" alt="image" width="75px"><div>Collaborate to combat Cybercrime</div></body>`,
    };
  },

  CLEAN_ROOM_INVITATION: (details) => {
    return {
      subject: "Invitation to Join PryvX Clean Room",
      body: `<body><p>Dear ${details.toFirstName}<b></b>,</p><br /><p>You have been invited to join the <b>${details.cleanRoomName}</b> as data collaborator by <b>${details.currentUserName}</b>.</p><p> <a href=${process.env.REACT_APP_REDIRECT_URL}cleanroom/${details.cleanRoomId}>Join the Clean Room</a></p><br /><div>The request is valid for 7 days.</div><img src="https://firebasestorage.googleapis.com/v0/b/pryvx-base.appspot.com/o/assets%2FPryvx-email-footer-logo.png?alt=media&token=f00555d6-dd65-4cc3-8c81-f41bcdaa150a" alt="image" width="75px"><div>Collaborate to combat Cybercrime</div></body>`,
    };
  },
};

const sendEmail = async (toEmail, template, details) => {
  // details must change according to the EMAIL_TEMPLATE
  try {
    const selectedTemplate = template(details);
    await api(API_REQUEST_TYPE.POST, API_CONFIG.END_POINTS.MAIL.SEND_MAIL, {
      emailId: toEmail,
      subject: selectedTemplate.subject,
      body: selectedTemplate.body,
    });
  } catch (error) {
    console.error(`ERROR SENDING EMAIL TO ${toEmail}: `, error);
  }
};
export default sendEmail;
