import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import TableDatagrid from "../../components/common/TableDatagrid/TableDatagrid";
import Search from "../../components/svg/Search";
import "./style.scss";

const CleanRoom = ({ user }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dateOptions = useMemo(() => {
    return { day: "2-digit", month: "long", year: "numeric" };
  }, []);

  const fetchMyData = useCallback(async () => {
    setIsLoading(true);
    setFilteredResults([]);
    try {
      const q = query(collection(db, "clean_room"));
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        const data = doc.data();
        const visibleRooms = data?.collaborators?.filter(
          (col) => col?.id === user?.uid || data?.userId === user?.uid
        )?.[0];
        if (visibleRooms) {
          list.push({ id: doc?.id, ...data });
        }
      });
      setData(list);
      setFilteredResults(list);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.uid]);

  useEffect(() => {
    fetchMyData();
  }, [fetchMyData]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchResult = data.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredResults(searchResult);
  };

  const userColumns = useMemo(() => {
    const currentDate = new Date().valueOf();
    const columns = [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => {
          return (
            <span
              onClick={() => navigate(`/cleanroom/${params.row.id}`)}
              className="hover:font-semibold  cursor-pointer"
            >
              {params.row.name}
            </span>
          );
        },
      },
      {
        field: "createDate",
        headerName: "Created",
        flex: 1,
        renderCell: (params) => {
          return (
            <span>
              {params.row?.createDate
                ? new Date(params.row?.createDate).toLocaleDateString(
                    "en-GB",
                    dateOptions
                  )
                : "-"}
            </span>
          );
        },
      },
      {
        field: "expireDate",
        headerName: "Ended",
        flex: 1,
        renderCell: (params) => {
          return (
            <span>
              {params.row?.expireDate
                ? new Date(params.row?.expireDate).toLocaleDateString(
                    "en-GB",
                    dateOptions
                  )
                : "-"}
            </span>
          );
        },
      },
      {
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          return (
            <span>
              {currentDate > params.row?.expireDate ? (
                <span className="status Ended">Ended</span>
              ) : (
                <span className="status Active">Active</span>
              )}
            </span>
          );
        },
      },
    ];
    return columns;
  }, [dateOptions, navigate]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setFilteredResults(data);
    }
  };

  return (
    <div className="h-[695px] p-[32px]">
      <div className="datatableTitle flex flex-wrap justify-between mb-4 gap-4">
        <div className="flex gap-[20px] items-center">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Clean Room
          </Typography>
          <div className="search border border-[#999999] py-[16px] px-[10px] rounded-full w-[250px] max-w-[75%] bg-white shadow-md relative">
            <form onSubmit={(e) => handleSearch(e)}>
              <input
                type="text"
                placeholder="Search Clean Room"
                value={searchTerm}
                className="w-full"
                onChange={(e) => handleSearchInputChange(e)}
              />
              <button type="submit">
                <span className="absolute right-[3px] top-[3px] bg-[#A0CCFB] w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer">
                  <Search />
                </span>
              </button>
            </form>
          </div>
        </div>

        <Link to="/cleanroom/register" className="">
          <Button
            variant={BUTTON_VARIANT.SECONDARY}
            className="text-[#323582] uppercase"
            color="primary"
          >
            + Create Clean Room
          </Button>
        </Link>
      </div>

      <div className="w-full h-full shadow-lg bg-white">
        <TableDatagrid
          rows={filteredResults}
          columns={userColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          isLoading={isLoading}
          checkboxSelection={false}
          initialState={{
            sorting: {
              sortModel: [{ field: "createDate", sort: "desc" }],
            },
          }}
        />
      </div>
    </div>
  );
};

export default CleanRoom;
