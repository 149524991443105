import React, { useMemo } from "react";
import { Box, Pagination, Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import Tabs from "../../components/common/Tabs";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import { makeStyles } from "@mui/styles";
import ModelCard from "./components/ModelCard";
import ProjectsNotCreate from "../../components/common/ProjectsNotCreatepage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.MuiPaginationItem-root":
      {
        color: "#999999",
      },
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#A0CCFB",
      color: "black",
    },
  },
}));

const ModelHub = ({ user }) => {
  const classes = useStyles();
  const [islaoding, setIsLoading] = useState(false);
  const [isRowLayout, setIsRowLayout] = useState(false);
  const [isColumnLayout, setIsColumnLayout] = useState(true);
  const [activeTab, setActiveTab] = useState("Wangiri");
  const [page, setPage] = useState(1);
  const [apis, setApis] = useState([]);
  const [apisOnPage, setApisOnPage] = useState([]);
  console.log(user);

  const PER_PAGE = 6;
  const count = Math.ceil(apis.length / PER_PAGE);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "model_hub"),
        where("type", "==", activeTab),
        where("modelUsers", "array-contains", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setApis(list);
      setApisOnPage(list.slice(0, PER_PAGE));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleChange = (_, p) => {
    if (p === null) {
      return;
    }
    setPage(p);
    setApisOnPage(apis.slice(PER_PAGE * (p - 1), p * PER_PAGE));
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setPage(1);
  };
  const renderDataNotFound = useMemo(() => {
    if (apisOnPage.length === 0) {
      return (
        <ProjectsNotCreate
          heading="No model to show yet."
          subHeading="You are currently not part of or have not been invited to join other model."
        />
      );
    }
  }, [apisOnPage, activeTab]);

  return (
    <div className="h-[695px] p-[32px] px-[40px] mt-8">
      <div className="datatableTitle flex flex-wrap justify-between mb-4  gap-4">
        <div className="flex flex-wrap text-nowrap items-center lg:w-[48%]">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Fraud detection Models
          </Typography>
        </div>
      </div>
      <div className="flex flex-wrap mt-[30px]  gap-3 justify-between mb-4">
        <Tabs
          names={["Wangiri", "SMS", "PBX hacking", "CLI spoofing", "SIM box"]}
          active={activeTab}
          onChangeActive={handleTabClick}
        />
      </div>
      <div className="mt-[35px]">
        {renderDataNotFound}
        <Grid container spacing={isRowLayout ? 1 : 5}>
          {apisOnPage.map((api) => (
            <Grid item lg={isColumnLayout ? 4 : 12} key={api.id}>
              <Link to={`/models/${api.id}`} style={{ textDecoration: "none" }}>
                <ModelCard isRowLayout={isRowLayout} api={api} />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box
          className="py-5"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            classes={{ root: classes.root }}
            count={count}
            size="small"
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </div>
      {islaoding && <OverlayLoader />}
    </div>
  );
};

export default ModelHub;
