import React from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";
import InfoFill from "../svg/InfoFill";
import CustomTooltip from "./CustomTooltip/CustomTooltip";

const InputTagContainer = ({
  tags = [],
  setTags = () => {},
  label = "",
  toolTipTitle = null,
  onAddNewTag = (newTag, allTags) => {},
  onRemoveTag = (allTags) => {},
  errorMessage,
  type,
  innerRef,
  ...props
}) => {
  const removeTags = (indexToRemove) => {
    const reminingTags = [
      ...tags.filter((_, index) => index !== indexToRemove),
    ];
    setTags(reminingTags);
    onRemoveTag(reminingTags);
  };
  const addTags = (event) => {
    const value = event.target.value.trim().toLowerCase();
    if (tags.includes(value)) {
      // To handle same emails multiple times
      event.target.value = "";
    } else if (value !== "") {
      // To handle case if user have not entereed any value
      setTags([...tags, value]);
      event.target.value = "";
      onAddNewTag(value, [...tags, value]);
    }
  };

  return (
    <div className="relative w-full flex flex-col items-start justify-start gap-[8px] z-[1]">
      <div className="absolute top-[-12px] bg-white ml-2 z-[2]">
        <Typography variant={TYPOGRAPHY_VARIANT.BODY_2}>{label}</Typography>
      </div>

      <div className="w-full relative flex items-start">
        <div className="w-full border-solid border-[1px] border-ui-dark-grey py-3 rounded-md flex flex-wrap focus:border-[#323582] focus:border-[1.5px] hover:border-[1.5px] hover:border-[#000000]">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="tag mt-1 bg-[#F2F2F2] rounded-[25px] p-[4px] ml-5"
            >
              <span className="text-[#333333] pl-[7px] font-avenir text-[13.6px]">
                {tag}
              </span>
              <span
                className="cursor-pointer px-[7px] p-[3px] text-black font-black hover:bg-slate-400 hover:text-[#fff] rounded-full"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </span>
          ))}
          <input
            className={"ml-5 mt-[8px] w-full"}
            type={type}
            ref={innerRef}
            onKeyUp={(event) => {
              return (event.keyCode === 13 || event.keyCode === 32) &&
                event.target.value
                  .trim()
                  .toLowerCase()
                  .match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                ? addTags(event)
                : null;
            }}
            placeholder="Enter Email Address"
            {...props}
          />
        </div>
        {toolTipTitle && (
          <CustomTooltip title={toolTipTitle}>
            <span>
              <InfoFill className="relative ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
            </span>
          </CustomTooltip>
        )}
      </div>
      <Typography variant={TYPOGRAPHY_VARIANT.BODY_2} className="text-warning">
        {errorMessage}
      </Typography>
    </div>
  );
};

export default InputTagContainer;
