import { Link } from "react-router-dom";
import CloseIcon from "../svg/CloseIcon";
import Typography, { TYPOGRAPHY_VARIANT } from "./Typography";

const SideDrawer = ({ show = false, hideDrawer = () => { }, title = "", body = <></> }) => {
    return (
        <div className={`fixed top-0 right-0 z-40 h-screen overflow-y-auto transition-transform  w-full dark:bg-gray-800 bg-[#0000004d] ${show ? "translate-x-unset" : "translate-x-fulls hidden"
            }`}
            tabIndex={-1}
            aria-labelledby="drawer-right-label">
            <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[75%] sm:w-[75%] w-[75%] ml-auto h-full bg-white px-14 py-12 overflow-auto">
                <div className="flex flex-wrap flex-col justify-between mb-8 gap-4 xl:w-[85%]">
                    <div className="flex items-center gap-4 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
                        <Link onClick={hideDrawer}>
                            <CloseIcon />
                        </Link>
                        <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
                            {title}
                        </Typography>
                    </div>
                    {show && <div className="ml-[62px]">{body}</div>}
                </div>
            </div>
        </div>)
}

export default SideDrawer;