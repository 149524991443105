import RotatingLoader from "../svg/RotatingLoader";

const OverlayLoader = ({ text }) => {
  return (
    <div
      role="status"
      className="fixed top-[0] bottom-[0] left-[0] right-[0] z-9 flex items-center justify-center bg-[#1e1e4cf5] z-[60]"
    >
      <div className="flex flex-col gap-6 w-[500] h-[218px]">
        <RotatingLoader />
        {text && <span className="text-white text-[32px]">{text}</span>}
      </div>
    </div>
  );
};

export default OverlayLoader;
