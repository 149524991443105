import { useNavigate } from "react-router-dom";
import Button, { BUTTON_VARIANT } from "../../../../components/common/Button";
import TableDatagrid from "../../../../components/common/TableDatagrid/TableDatagrid";
import { useEffect, useMemo, useState } from "react";
import Search from "../../../../components/svg/Search";

const CollaboratorTab = (props) => {
  const { currentUser, openInvitationModal, isLoading, tableData, showAddBtn } =
    props;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTableData, setFilteredTableData] = useState(tableData);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [tableData]);

  const userColumns = useMemo(() => {
    const columns = [
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "ability",
        headerName: "Collaborator Abilities",
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => {
          const canOpenInvitationModal =
            params.row.email === currentUser.email &&
            params.row.status === "Pending";
          return (
            <span
              onClick={() => {
                canOpenInvitationModal && openInvitationModal();
              }}
              className={`status ${params.row?.status} ${
                canOpenInvitationModal ? "cursor-pointer" : ""
              }`}
            >
              {params.row?.status}
            </span>
          );
        },
      },
    ];
    return columns;
  }, [currentUser.email, openInvitationModal]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchResult = tableData.filter((item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTableData(searchResult);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setFilteredTableData(tableData);
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-5">
        <div className="search border border-[#999999] py-[16px] px-[10px] rounded-full w-[250px] max-w-[75%] bg-white shadow-md relative">
          <form onSubmit={(e) => handleSearch(e)}>
            <input
              type="text"
              placeholder="Search Collaborator"
              value={searchTerm}
              className="w-full"
              onChange={(e) => handleSearchInputChange(e)}
            />
            <button type="submit">
              <span className="absolute right-[3px] top-[3px] bg-[#A0CCFB] w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer">
                <Search />
              </span>
            </button>
          </form>
        </div>
        {showAddBtn && (
          <div>
            <Button
              variant={BUTTON_VARIANT.PRIMARY}
              className="flex items-center gap-2"
              onClick={() => navigate("add/collaborator")}
              buttonClassName="flex items-center gap-2"
            >
              ADD COLLABORATOR
            </Button>
          </div>
        )}
      </div>

      <div className="mt-5 h-[500px]">
        <TableDatagrid
          rows={filteredTableData}
          columns={userColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          isLoading={isLoading}
          checkboxSelection={false}
        />
      </div>
    </div>
  );
};

export default CollaboratorTab;
