import React, { useMemo } from "react";
import { Box, Pagination, Unstable_Grid2 as Grid } from "@mui/material";

import "./style.scss";
import ProjectCard from "./components/ProjectCard";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import Tabs from "../../components/common/Tabs";
import Search from "../../components/svg/Search";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import OverlayLoader from "../../components/common/OverlayLoader";
import { makeStyles } from "@mui/styles";
import ColumnIcon from "../../components/svg/ColumnIcon";
import RowIcon from "../../components/svg/RowIcon";
import ProjectsNotCreate from "../../components/common/ProjectsNotCreatepage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.MuiPaginationItem-root":
      {
        color: "#999999",
      },
    "& .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#A0CCFB",
      color: "black",
    },
  },
}));

const Projects = ({ user }) => {
  const classes = useStyles();
  const [projects, setProject] = useState([]);
  const [projectsOnPage, setProjectsOnPage] = useState([]);
  const [activeTab, setActiveTab] = useState("My Projects");
  const [activeClass, setActiveClass] = useState("datasetColumn");
  const [islaoding, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isRowLayout, setIsRowLayout] = useState(false);
  const [isColumnLayout, setIsColumnLayout] = useState(true);

  const PER_PAGE = 6;
  const count = Math.ceil(projects.length / PER_PAGE);

  const handleChange = (_, p) => {
    if (p === null) {
      return;
    }
    setPage(p);
    setProjectsOnPage(projects.slice(PER_PAGE * (p - 1), p * PER_PAGE));
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "projects"),
        where("userId", "!=", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        const data = doc.data();
        const userAsCollaborator = data.collaborators.filter(
          (col) => col.userId === user.uid
        )?.[0];
        if (userAsCollaborator) {
          list.push({ id: doc.id, ...data });
        }
      });
      setProject(list);
      setProjectsOnPage(list.slice(0, PER_PAGE));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMyData = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "projects"),
        where("userId", "==", user.uid)
      );
      const snapShot = await getDocs(q);
      let list = [];
      snapShot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setProject(list);
      setProjectsOnPage(list.slice(0, PER_PAGE));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeTab === "My Projects") {
      fetchMyData();
    } else if (activeTab === "All") {
      fetchAllData();
    }
  }, [activeTab, user.uid]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setPage(1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchResult = projects.filter((item) =>
      item.projectName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProjectsOnPage(searchResult);
    setProject(searchResult);
    setPage(1);
    setProjectsOnPage(searchResult.slice(0, PER_PAGE));
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      if (activeTab === "My Projects") {
        fetchMyData();
      } else if (activeTab === "All") {
        fetchAllData();
      }
    }
  };

  const handleColmButtonClick = (active) => {
    setIsRowLayout(false);
    setIsColumnLayout(true);
    setActiveClass(active);
  };

  const handleRowButtonClick = (active) => {
    setIsRowLayout(true);
    setIsColumnLayout(false);
    setActiveClass(active);
  };

  const renderDataNotFound = useMemo(() => {
    if (projectsOnPage.length === 0) {
      if (activeTab === "My Projects") {
        return (
          <ProjectsNotCreate
            heading="No projects created yet."
            subHeading="Click the Create Project button to get started."
            showButton={true}
            buttonText="+ CREATE PROJECT"
            linkTo="/projects/new"
          />
        );
      } else if (activeTab === "All") {
        return (
          <ProjectsNotCreate
            heading="No projects to show yet."
            subHeading="You are currently not part of or have not been invited to join other projects."
          />
        );
      }
    }
  }, [projectsOnPage, activeTab]);

  return (
    <div className="h-[695px] p-[32px] mt-10">
      <div className="datatableTitle flex flex-wrap justify-between mb-4 gap-4">
        <div className="flex gap-[20px] items-center">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Projects
          </Typography>
          <div className="search border border-[#999999] py-[16px] px-[10px] rounded-full w-[250px] max-w-[75%] bg-white shadow-md relative">
            <form onSubmit={(e) => handleSearch(e)}>
              <input
                type="text"
                placeholder="Search by Project Name"
                value={searchTerm}
                className="w-full"
                onChange={(e) => handleSearchInputChange(e)}
              />
              <button type="submit">
                <span className="absolute right-[3px] top-[3px] bg-[#A0CCFB] w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer">
                  <Search />
                </span>
              </button>
            </form>
          </div>
        </div>
        <div className="flex gap-4">
          <span
            onClick={() => handleColmButtonClick("datasetColumn")}
            className={`flex cursor-pointer drop-shadow-md ${
              activeClass === "datasetColumn" ? "bg-[#a0ccfb66]" : "bg-white"
            } active:bg-gray-200 items-center justify-center rounded-full w-[48px] min-w-[48px] h-[48px]`}
          >
            <ColumnIcon />
          </span>
          <span
            onClick={() => handleRowButtonClick("datasetRow")}
            className={`flex cursor-pointer drop-shadow-md ${
              activeClass === "datasetRow" ? "bg-[#a0ccfb66]" : "bg-white"
            } active:bg-gray-200 items-center justify-center rounded-full w-[48px] min-w-[48px] h-[48px]`}
          >
            <RowIcon />
          </span>

          <Link to="/projects/new" className="">
            <button className="flex items-center px-[32px] py-[10px] text-[#323582] border border-[#323582] rounded-full gap-[4px]  bg-white shadow-md relative">
              <b> + CREATE PROJECT</b>
            </button>
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap gap-3 justify-between mb-4">
        <Tabs
          names={["My Projects", "All"]}
          active={activeTab}
          onChangeActive={handleTabClick}
        />
      </div>
      <div className="mt-[50px]">
        {renderDataNotFound}
        <div className="flex gap-10 flex-wrap">
          {projectsOnPage.map((project) => (
            <div
              className={`flex gap-4 ${
                isColumnLayout
                  ? "xl:basis-[30.5%] lg:basis-[45%] md:basis-[85%] sm:basis-[85%] 2xl:w-[30%] lg:w-[45%] md:w-[85%] sm:w-[85%] rounded-[12%]"
                  : "xl:basis-[100%] lg:basis-[100%]"
              } justify-center w-full bg-white border-[#999999] rounded-[1em]`}
              key={project.id}
            >
              <Link
                to={`/projects/${project.id}`}
                style={{ textDecoration: "none" }}
                className="w-full"
              >
                <ProjectCard isRowLayout={isRowLayout} project={project} />
              </Link>
            </div>
          ))}
        </div>
        <Box
          className="py-5"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            classes={{ root: classes.root }}
            count={count}
            size="small"
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </Box>
      </div>
      {islaoding && <OverlayLoader />}
    </div>
  );
};

export default Projects;
