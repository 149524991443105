export const EqualIcon = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 10V7H16V10H0ZM0 3V0H16V3H0Z" fill="#5F6368" />
    </svg>
  );
};
