export default function cleanObject(obj, keysToSkip = []) {
  if (Array.isArray(obj)) {
    // If it's an array, recursively clean each element and filter out empty objects
    return obj
      .map((item) => cleanObject(item, keysToSkip))
      .filter((item) => !(isEmptyObject(item) || isEmptyValue(item)));
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, recursively clean each key
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = cleanObject(value, keysToSkip);
      if (!isEmptyValue(cleanedValue) || keysToSkip.includes(key)) {
        acc[key] = keysToSkip.includes(key) ? value : cleanedValue;
      }
      return acc;
    }, {});
  }
  // Return primitives as is, unless they are empty
  return obj;
}
export function isEmptyObject(value) {
  return (
    typeof value === "object" &&
    value !== null &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  );
}

export function isEmptyValue(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    // commented because we want empty array
    //(Array.isArray(value) && value.length === 0) ||
    isEmptyObject(value)
  );
}
