import { useFieldArray } from "react-hook-form";
import Button, { BUTTON_VARIANT } from "../../../../components/common/Button";
import SelectField, {
  LABEL_TYPE,
} from "../../../../components/common/SelectField";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import Close from "../../../../components/svg/Close";

const AGGREGATE = ["MIN", "MAX", "COUNT", "SUM", "AVG"];
export const INITIAL_AGGREGATE_FIELD_VALUES = { aggregate: "", column: "" };

const AggregateTaskForm = ({ control, register, errors, allColumnOptions }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "aggregateFun",
  });

  const addAggregateIdRow = () => {
    append(INITIAL_AGGREGATE_FIELD_VALUES);
  };
  const removeAggregateIdRow = (ind) => {
    remove(ind);
  };

  const aggregateFunCoulumn = AGGREGATE.map((columnName) => {
    return { value: columnName, label: columnName };
  });

  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <div className="flex mt-3 gap-5">
        <div className="flex-1">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4} className="mb-3">
            Aggregate Function
          </Typography>
        </div>
        <div className="flex-1">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4} className="mb-3">
            Column
          </Typography>
        </div>
        <div className="w-[4rem]" />
      </div>

      <div>
        {fields.map((field, ind) => (
          <div className="flex mt-3 gap-5" key={field.id}>
            <div className="flex-1">
              <SelectField
                control={control}
                labelType={LABEL_TYPE.OUTLINED}
                options={aggregateFunCoulumn}
                placeholder="Choose Function"
                innerRef={register(`aggregateFun.${ind}.aggregate`).ref}
                {...register(`aggregateFun.${ind}.aggregate`, {
                  required: "Column in table 1 is required.",
                })}
                errorMessage={errors?.aggregateFun?.[ind]?.aggregate?.message}
                isError={!!errors?.aggregateFun?.[ind]?.aggregate?.message}
              />
            </div>
            <div className="flex-1">
              <SelectField
                control={control}
                labelType={LABEL_TYPE.OUTLINED}
                options={allColumnOptions}
                placeholder="Choose Column"
                innerRef={register(`aggregateFun.${ind}.column`).ref}
                {...register(`aggregateFun.${ind}.column`, {
                  required: "Column is required.",
                })}
                errorMessage={errors?.aggregateFun?.[ind]?.column?.message}
                isError={!!errors?.aggregateFun?.[ind]?.column?.message}
              />
            </div>
            <div
              className="pt-4 px-5"
              onClick={() => removeAggregateIdRow(ind)}
            >
              <Close
                height="16"
                width="16"
                className="fill-ui-grey-50 hover:fill-brand-marine-blue"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="w-[15rem]">
        <Button
          onClick={addAggregateIdRow}
          type={"button"}
          variant={BUTTON_VARIANT.PRIMARY}
          className="mt-3"
        >
          ADD FUNCTION
        </Button>
      </div>
    </div>
  );
};

export default AggregateTaskForm;
