import "./style.scss";
import { useState, useContext } from "react";
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import InputTextField, {
  LABEL_TYPE,
} from "../../components/common/InputTextField";
import InputTextArea from "../../components/common/InputTextArea";
import InputTagContainer from "../../components/common/InputTagContainer";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import OverlayLoader from "../../components/common/OverlayLoader";
import { SnackbarContext } from "../../context/SnackbarContext";
import CustomDatePicker from "../../components/common/CustomDatePicker";
import checkEmailRegistered from "../../utils/firebase/checkEmailRegistered";
import { AuthContext } from "../../context/AuthContext";
import sendEmail, { EMAIL_TEMPLATE } from "../../utils/sendEmail";
import addUnregisteredCollaborator from "../../utils/firebase/addUnregisteredCollaborator";

const RegisterCleanRoom = ({ user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dbOperatorTags, setDbOperatorTags] = useState([]);
  const [emailTagRegisteredUserDetails, setEmailTagRegisteredUserDetails] =
    useState([]);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const { currentUser } = useContext(AuthContext);
  const {
    control,
    register,
    handleSubmit: handleFormSubmit,
    formState,
  } = useForm();
  const { errors } = formState;

  const handleAddNewTag = async (newTag) => {
    const emailUserDetails = await checkEmailRegistered(newTag);
    if (emailUserDetails) {
      setEmailTagRegisteredUserDetails({
        ...emailTagRegisteredUserDetails,
        [emailUserDetails?.email]: emailUserDetails,
      });
    }
  };

  const handleRemoveTag = async (remaingTags) => {
    // updating emailTagRegisteredUserDetails
    let remainingTagValues = {};
    remaingTags.forEach((tag) => {
      remainingTagValues[tag] = emailTagRegisteredUserDetails[tag];
    });
    setEmailTagRegisteredUserDetails(remainingTagValues);
  };

  const handleSubmit = async (data) => {
    setLoading(true);

    try {
      const collaborationData = dbOperatorTags.map((operatorEmail) => {
        const registered = Object.keys(emailTagRegisteredUserDetails).includes(
          operatorEmail
        );

        return {
          email: operatorEmail,
          ability: "Contribute Data",
          status: "Pending",
          registered: registered,
          firstName:
            emailTagRegisteredUserDetails[operatorEmail]?.firstName || "",
          id: emailTagRegisteredUserDetails[operatorEmail]?.id || "",
        };
      });
      const newCleanRoomData = {
        userId: user.uid,
        name: data.cleanRoomName,
        description: data.description,
        collaborators: collaborationData,
        createDate: Date.now(),
        expireDate: new Date(data.expireDate).valueOf(),
      };
      const res = await addDoc(collection(db, "clean_room"), newCleanRoomData);

      snackbarDispatch({
        type: "SHOW_SUCCESS_SNACKBAR",
        payload: "Clean Room created successfully.",
      });

      collaborationData.forEach(async (collaborator) => {
        if (!collaborator.registered) {
          //make unregistered user if user is not registered
          await addUnregisteredCollaborator(
            collaborator.email,
            "cleanRoomId",
            res.id
          );
        }
        //send email
        await sendEmail(
          collaborator.email,
          EMAIL_TEMPLATE.CLEAN_ROOM_INVITATION,
          {
            toFirstName: collaborator.firstName
              ? collaborator.firstName
              : collaborator.email.split("@")[0],
            cleanRoomName: data.cleanRoomName,
            cleanRoomId: res.id,
            currentUserName: currentUser.userDetails.firstName,
          }
        );
      });

      setLoading(false);
      navigate("/cleanroom");
    } catch (error) {
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while creating clean room.",
      });
      console.error(error);
      setLoading(false);
    }
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  if (loading) {
    return <OverlayLoader />;
  }

  return (
    <div className="register bg-white h-full p-10">
      <form
        className="px-[50px]"
        onSubmit={handleFormSubmit(handleSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="flex flex-col gap-4 mb-8 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
          <Typography variant={TYPOGRAPHY_VARIANT.HEADING_3}>
            Create Clean Room
          </Typography>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            Creating a data clean room guarantees the confidentiality and
            security of data.
          </Typography>
          <div className="border-grey border-[1px] rounded-3xl p-5">
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Name Clean Room
              </Typography>
              <div className="flex-1">
                <InputTextField
                  labelType={LABEL_TYPE.OUTLINED}
                  placeholder="Enter Clean Room Name"
                  type="text"
                  innerRef={register("cleanRoomName").ref}
                  {...register("cleanRoomName", {
                    required: "Clean Room Name is required.",
                  })}
                  errorMessage={errors?.cleanRoomName?.message}
                  isError={!!errors?.cleanRoomName?.message}
                />
              </div>
            </div>
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Description
              </Typography>
              <div className="flex-1">
                <InputTextArea
                  labelType={LABEL_TYPE.OUTLINED}
                  placeholder="Enter Description (Optional)"
                  rows={5}
                  type="text"
                  innerRef={register("description").ref}
                  {...register("description")}
                  errorMessage={errors?.description?.message}
                  isError={!!errors?.description?.message}
                />
              </div>
            </div>
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Date Expired
              </Typography>
              <div className="flex-1">
                <CustomDatePicker
                  control={control}
                  innerRef={register("expireDate").ref}
                  {...register("expireDate", {
                    required: "Expire Date is required.",
                  })}
                  errorMessage={errors?.expireDate?.message}
                  isError={!!errors?.expireDate?.message}
                  toolTipTitle="Select a date when you want this clean room to automatically expire, to protect its integrity and privacy."
                />
              </div>
            </div>
          </div>
          <div className="border-grey border-[1px] rounded-3xl p-5">
            <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
              Invite collaborators
            </Typography>
            <div className="flex">
              <Typography
                variant={TYPOGRAPHY_VARIANT.SUB_HEADING_5}
                className="flex-2"
              >
                Company Email
              </Typography>
              <div className="flex-1">
                <InputTagContainer
                  errorMessage={errors?.dataOperetor?.message}
                  isError={!!errors?.dataOperetor?.message}
                  tags={dbOperatorTags}
                  setTags={setDbOperatorTags}
                  onAddNewTag={handleAddNewTag}
                  onRemoveTag={handleRemoveTag}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-[16px] justify-end justify-self-end justify-item-end">
            <Button
              type="button"
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={() => navigate("/cleanroom")}
              className="w-[300px]"
            >
              CANCEL
            </Button>
            <Button
              variant={
                errors.datasetName || errors.desc
                  ? BUTTON_VARIANT.DISABLED
                  : BUTTON_VARIANT.PRIMARY
              }
              type="submit"
              className="w-[300px]"
            >
              CREATE ROOM
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterCleanRoom;
