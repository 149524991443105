import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, { API_CONFIG, API_REQUEST_TYPE } from "../../api/api";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../components/common/Typography";
import ShowSqlQuery from "./components/ShowSqlQuery";
import ShowTable from "./components/ShowTable";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShowTaskDetails from "./components/ShowTaskDetails";
import Button, { BUTTON_VARIANT } from "../../components/common/Button";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import NotFound from "../404notfound/NotFound";
import ArrowBack from "../../components/svg/ArrowBack";
import OverlayLoader from "../../components/common/OverlayLoader";
import { SnackbarContext } from "../../context/SnackbarContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ViewCleanRoomTask = ({ user }) => {
  const { id: cleanRoomId, taskId } = useParams();
  const [tableData, setTableData] = useState([{}]);
  const [taskInfo, setTaskInfo] = useState({});
  const [isExpire, setIsExpire] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);

  const getTaskDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const cleanRoomRef = doc(db, "clean_room", cleanRoomId);
      const taskRef = doc(cleanRoomRef, "tasks", taskId);
      const [taskSnapshot, cleanroomSnapshot] = await Promise.all([
        getDoc(taskRef),
        getDoc(cleanRoomRef),
      ]);
      if (taskSnapshot.exists()) {
        setTaskInfo(taskSnapshot.data());
      }
      if (cleanroomSnapshot.exists()) {
        setIsExpire(
          new Date().valueOf() > cleanroomSnapshot.data()?.expireDate
        );
      }
      setIsLoading(false);
    } catch (err) {
      console.error("error", err);
      setIsLoading(false);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while Refetching task details.",
      });
    }
  }, [cleanRoomId, snackbarDispatch, taskId]);

  const getOutputTable = useCallback(async () => {
    try {
      setIsTableLoading(true);
      const queryRes = await api(
        API_REQUEST_TYPE.POST,
        API_CONFIG.END_POINTS.CLEAN_ROOM.EXECUTE_SQL_TASK_QUERY(
          cleanRoomId,
          taskId
        ),
        {}
      );
      const resTable = await queryRes.json();
      if (queryRes.status === 200 && resTable.message === "SUCCESS") {
        setTableData(resTable.output);
        snackbarDispatch({
          type: "SHOW_SUCCESS_SNACKBAR",
          payload: "Task Completed Successfully.",
        });
      } else {
        console.error(resTable);
        setErrorMessage(
          "Opps! Got an error building the SQL query. Please Check."
        );
        // snackbarDispatch({
        //   type: "SHOW_ERROR_SNACKBAR",
        //   payload: "Error while Running Task.",
        // });
      }
      setIsTableLoading(false);
    } catch (err) {
      console.error("error", err);
      setIsTableLoading(false);
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Error while Running Task.",
      });
    }
  }, [cleanRoomId, snackbarDispatch, taskId]);

  useEffect(() => {
    if (cleanRoomId && taskId) {
      getTaskDetails();
    }
  }, [cleanRoomId, taskId, getTaskDetails]);

  const downloadCSV = (data, filename = `data.csv`) => {
    if (!data || Object.keys(data[0]).length === 0) {
      console.error("No data to download");
      snackbarDispatch({
        type: "SHOW_ERROR_SNACKBAR",
        payload: "Run Query to get the data then try downloading.",
      });
      return;
    }

    // Extract column headers
    const headers = Object.keys(data[0]).join(",");

    // Convert array of objects to CSV string
    const csvRows = data.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(",")
    );

    // Combine headers and rows
    const csvString = [headers, ...csvRows].join("\n");

    // Create Blob and generate a download link
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const isOwner = taskInfo?.userId === user?.uid;

  const handleRunQuery = async () => {
    await getOutputTable();
    getTaskDetails();
    setIsResultOpen(true);
  };

  if (isLoading || isTableLoading) {
    return <OverlayLoader />;
  }

  if (!cleanRoomId || !taskId || !taskInfo?.taskName || !taskInfo?.query) {
    return (
      <NotFound
        pageName="clean room task"
        message={"Task is only visible just after task gets created."}
        btnNavigatePath={`/cleanroom/${cleanRoomId}`}
      />
    );
  }

  return (
    <div className="p-[32px]">
      <div className="datatableTitle flex flex-wrap justify-between mb-2 gap-4">
        <div className="flex flex-col flex-wrap gap-4 mb-8 w-full">
          <div className="flex">
            <Link
              to={`/cleanroom/${cleanRoomId}`}
              state={{ tabName: "Task" }}
              className="flex gap-2 hover:fill-brand-marine-blue mr-5"
            >
              <ArrowBack />
            </Link>
            <Typography
              variant={TYPOGRAPHY_VARIANT.HEADING_3}
              className="content-end"
            >
              {taskInfo?.taskName}
            </Typography>
          </div>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_1}>
            Perform specific operations to securely manage and analyze data with
            full control.
          </Typography>
        </div>
      </div>

      <div className="flex gap-4 flex-col">
        <div>
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
            SQL Query
          </Typography>
          <ShowSqlQuery
            runQuery={handleRunQuery}
            query={taskInfo?.query}
            showRunBtn={isOwner && !isExpire}
          />
        </div>

        <div>
          {isOwner && !isExpire && (
            <Accordion expanded={isResultOpen}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => setIsResultOpen(!isResultOpen)}
              >
                <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
                  Task Result
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {errorMessage ? (
                  <Alert severity="error">{errorMessage}</Alert>
                ) : Object.keys(tableData[0]).length === 0 ? (
                  <Alert severity="info">Note: Run Query to view Result.</Alert>
                ) : (
                  <ShowTable tableData={tableData.slice(0, 10)} />
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </div>

        {taskInfo?.userId === user?.uid && !isExpire && (
          <div className="flex justify-end">
            <div className="w-[182px]">
              <Button
                variant={BUTTON_VARIANT.SECONDARY}
                className="text-[#1E1E4C] uppercase"
                onClick={() =>
                  downloadCSV(tableData, `${taskInfo?.taskName} output.csv`)
                }
              >
                <DownloadOutlinedIcon />
                Task Result
              </Button>
            </div>
          </div>
        )}

        <Paper variant="outlined" className="p-4 flex flex-col gap-3">
          <Typography variant={TYPOGRAPHY_VARIANT.BODY_BOLD_1}>
            Task Detail
          </Typography>
          <ShowTaskDetails
            createDate={taskInfo.createDate}
            userEmail={taskInfo.userEmail}
            status={taskInfo.status}
            totalTransactions={taskInfo.totalTransactions}
          />
        </Paper>
      </div>
    </div>
  );
};
export default ViewCleanRoomTask;
