import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const checkEmailRegistered = async (email) => {
  try {
    const q = query(collection(db, "users"), where("email", "==", email));
    const snapShot = await getDocs(q);
    console.log("snapShot", snapShot);

    if (snapShot.docs?.[0]) {
      return { ...snapShot.docs?.[0].data(), id: snapShot.docs?.[0].id };
    }
  } catch (e) {
    console.error(`ERROR FETCHING ${email} EMAIL DATA: `, e);
  }
};

export default checkEmailRegistered;
