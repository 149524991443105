import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../components/common/Typography";

const dateOptions = { day: "2-digit", month: "long", year: "numeric" };

const ShowTaskDetails = ({
  createDate,
  userEmail,
  status,
  totalTransactions,
}) => {
  return (
    <div className="p-0 flex flex-col gap-1">
      <div>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}
          className="text-error-orange pr-2"
        >
          Task Owner:
        </Typography>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_2}
          className="text-success-green"
        >
          {userEmail}
        </Typography>
      </div>
      <div>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}
          className="text-error-orange pr-2"
        >
          Total Transactions:
        </Typography>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_2}
          className="text-success-green"
        >
          {totalTransactions}
        </Typography>
      </div>
      <div>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}
          className="text-error-orange pr-2"
        >
          Task At:
        </Typography>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_2}
          className="text-success-green"
        >
          {new Date(createDate).toLocaleDateString("en-GB", dateOptions)}
        </Typography>
      </div>
      <div>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_BOLD_2}
          className="text-error-orange pr-2"
        >
          Status:
        </Typography>
        <Typography
          variant={TYPOGRAPHY_VARIANT.BODY_2}
          className="text-success-green"
        >
          {!!status ? status : "-"}
        </Typography>
      </div>
    </div>
  );
};

export default ShowTaskDetails;
