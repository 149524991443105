import {
  getAdditionalUserInfo,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import OverlayLoader from "../../components/common/OverlayLoader";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import EnterEmailModal from "../../components/modals/EnterEmailModal";
import { ERROR_MAPPER } from "../../utils/const";
import { v4 as uuidv4 } from "uuid";

const ContinueLogin = () => {
  const auth = getAuth();
  const { dispatch } = useContext(AuthContext);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [showLoaderText, setShowLoaderText] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    emailSignIn();
  }, []);

  const emailSignIn = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        setEmailModalOpen(true);
      } else {
        signinWithEmailLinkWithEmail(email);
      }
    }
  };

  const signinWithEmailLinkWithEmail = (email) => {
    signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        if (getAdditionalUserInfo(result)?.isNewUser) {
          setShowLoaderText(true);
          const email = auth.currentUser.email;
          const userName = email.split("@")[0];

          // creating users detials in users collection
          await setDoc(doc(db, "users", auth.currentUser.uid), {
            firstName: userName,
            lastName: "",
            email,
            profileUrl: "",
            showModelHub: false,
          });

          // Create user-access-key collection for new user
          await setDoc(doc(db, "user_access_keys", auth.currentUser.uid), {
            clientAccessKey: uuidv4(),
          });

          // If data is present in UnregisteredCollaborators then update it and projects.
          const unregisteredCollRef = doc(
            db,
            "unregistered_collaborators",
            email
          );
          const unregisteredCollSnapshot = await getDoc(unregisteredCollRef);
          if (unregisteredCollSnapshot.exists()) {
            const unregData = unregisteredCollSnapshot.data();
            // update projects according to project id
            unregData?.projectId?.forEach(async (projectId) => {
              const projectRef = doc(db, "projects", projectId);
              const projectSnapshot = await getDoc(projectRef);
              if (projectSnapshot.exists()) {
                const projectData = projectSnapshot.data();
                const transferColl = projectData.unregisterCollaborators.filter(
                  (data) => data.email === email
                )[0];
                const unregistedColl =
                  projectData.unregisterCollaborators.filter(
                    (data) => data.email !== email
                  );
                const coll = [
                  ...projectData.collaborators,
                  { ...transferColl, userId: auth.currentUser.uid },
                ];
                await updateDoc(projectRef, {
                  collaborators: coll,
                  unregisterCollaborators: unregistedColl,
                });
              }
            });
            // update clean room according to cleanroom id
            unregData?.cleanRoomId?.forEach(async (cleanRoomId) => {
              const cleanRoomRef = doc(db, "clean_room", cleanRoomId);
              const cleanRoomSnapshot = await getDoc(cleanRoomRef);

              if (cleanRoomSnapshot.exists()) {
                const cleanRoomData = cleanRoomSnapshot.data();
                const collaborators = cleanRoomData.collaborators;

                const currentCollaborator = collaborators.filter(
                  (coll) => coll.email === email
                )?.[0];

                if (currentCollaborator) {
                  const updatedCurrentCollaborator = {
                    ...currentCollaborator,
                    registered: true,
                    firstName: email.split("@")[0],
                    id: auth.currentUser.uid,
                  };

                  const updatedAllCollaborators = collaborators.map((coll) =>
                    coll.email === email ? updatedCurrentCollaborator : coll
                  );

                  try {
                    await updateDoc(cleanRoomRef, {
                      collaborators: updatedAllCollaborators,
                    });
                  } catch (e) {
                    console.error(
                      "ERROR UPDATING CLEAN ROOM COLLABORATOR: ",
                      e
                    );
                  }
                }
              }
            });
            // deleating this cleanroom id at the end
            deleteDoc(unregisteredCollRef);
          }
        }
        const userRef = doc(db, "users", result.user.uid);
        const userSnapshot = await getDoc(userRef);
        if (!userSnapshot.exists()) {
          throw new Error({ code: "User Not found" });
        }
        dispatch({
          type: "LOGIN",
          payload: { ...result.user, userDetails: userSnapshot.data() },
        });
        navigate("/cleanroom");
      })
      .catch((error) => {
        console.error("error: ", error);
        navigate("/login", {
          state: { error: ERROR_MAPPER[error.code] ?? error.code },
        });
      });
  };

  const handleSConfirmtEnterEmailModal = () => {
    if (!!enteredEmail) {
      setEmailModalOpen(false);
      signinWithEmailLinkWithEmail(enteredEmail);
    }
  };

  const handleClosetEnterEmailModal = () => {
    setEmailModalOpen(false);
  };

  return (
    <>
      {emailModalOpen ? (
        <EnterEmailModal
          handleClose={handleClosetEnterEmailModal}
          handleConfirm={handleSConfirmtEnterEmailModal}
          enteredEmail={enteredEmail}
          setEnteredEmail={setEnteredEmail}
        />
      ) : (
        <OverlayLoader
          text={showLoaderText ? "Setting up your account..." : ""}
        />
      )}
    </>
  );
};

export default ContinueLogin;
