import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography, {
  TYPOGRAPHY_VARIANT,
} from "../../../../components/common/Typography";
import CustomTooltip from "../../../../components/common/CustomTooltip/CustomTooltip";
import InfoFill from "../../../../components/svg/InfoFill";

const PreviewFile = ({ fileValues }) => {
  return (
    <div className="flex relative flex-col border-dark-grey border-[1px] rounded-3xl p-5">
      <div className="flex">
      <Typography variant={TYPOGRAPHY_VARIANT.HEADING_4}>
        Preview Data
      </Typography>
      <CustomTooltip title={"Please check the selected dataset, verify the headers and data"}>
        <div>
          <InfoFill className="ml-[4px] right-[0] top-[16px] fill-ui-grey-50 hover:fill-brand-marine-blue" />
        </div>
      </CustomTooltip>
      </div>
      <TableContainer component={Paper} className="mt-4">
        <Table>
          <TableHead className="bg-gray-200">
            {fileValues.sample?.[0].map((value) => {
              return <TableCell key={value}>{value}</TableCell>;
            })}
          </TableHead>
          <TableBody>
            {fileValues.sample.slice(1, 6).map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((value, idx) => (
                  <TableCell key={idx}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PreviewFile;
