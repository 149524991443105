import { useMemo } from "react";
import Button, { BUTTON_VARIANT } from "../../../components/common/Button";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";

const LINE_BREAK_KEYWORDS = [
  "SELECT",
  "FROM",
  "ON",
  "WHERE",
  "OUTER",
  "INNER",
  "AND",
  "GROUP",
];

const QUERY_KEYWORDS = ["JOIN", "BY", "=", ">", "<"];

const TABLE_KEYWORDS = ["t1", "t2"];

const ShowSqlQuery = ({ query, runQuery, showRunBtn }) => {
  const formatedQuery = useMemo(() => {
    const queryArray = query?.split(" ");
    const splittedQuery = queryArray.map((word, ind) => {
      const prevWord = queryArray[ind - 1];
      if (
        word[word.length - 1] === "," ||
        prevWord?.[prevWord?.length - 1] === ","
      ) {
        return `\n<span style="color:#F3C33C; margin-left:20px">${word}</span>`;
      } else if (word[0] === "'" && word[word.length - 1] === "'") {
        return `<span style="color:#cac8c8;">${word}</span>`;
      } else if (TABLE_KEYWORDS.includes(word)) {
        return `<span style="color:pink;">${word}</span>`;
      } else if (LINE_BREAK_KEYWORDS.includes(word)) {
        return `\n<span style="color:#2AACC3;">${word}</span>`;
      } else {
        return QUERY_KEYWORDS.includes(word)
          ? `<span style="color:#2AACC3;">${word}</span>`
          : word
              .split(".")
              .map((dotWord) => {
                return TABLE_KEYWORDS.includes(dotWord)
                  ? `<span style="color:pink;">${dotWord}</span>`
                  : dotWord;
              })
              .join(".");
      }
    });
    return splittedQuery.join(" ");
  }, [query]);

  const queryWithHtml = useMemo(() => {
    return formatedQuery?.split("\n").map((line) => {
      if (line.trim() !== "") return `<li style="color:#309E63;">${line}</li>`;
      else return "";
    });
  }, [formatedQuery]);
  // console.log("formatedQuery", formatedQuery, "queryWithHtml", queryWithHtml);

  return (
    <div className="pt-[5px] px-[10px] mt-[25px] text-white border-2 bg-brand-deep-blue rounded-[10px]">
      <div className="flex border-b justify-end pb-[5px] min-h-10">
      {showRunBtn && (
        <div className="w-[180px]">
          <Button
            variant={BUTTON_VARIANT.BRAND_DEEP_BLUE}
            buttonClassName="y-[4px] px-[4px] font-normal"
            onClick={runQuery}
          >
            <PlayArrowOutlinedIcon />
            Run Query
          </Button>
        </div>
      )}
      </div>
      <div className="flex gap-4 p-3">
        <div>
          <ul className="text-[#999999]">
            {[...Array(queryWithHtml?.length - 1).keys()].map((val) => (
              <li>{val + 1}</li>
            ))}
          </ul>
        </div>
        <div
          id="client-code"
          style={{ whiteSpace: "nowrap" }}
          className="overflow-x-auto"
        >
          <ul dangerouslySetInnerHTML={{ __html: queryWithHtml.join(" ") }} />
          <div className="mb-[20px]" />
        </div>
      </div>
    </div>
  );
};

export default ShowSqlQuery;
