import React from "react";
import Typography, { TYPOGRAPHY_VARIANT } from "../common/Typography";
import Button, { BUTTON_VARIANT } from "../common/Button";
import InputTextField, { LABEL_TYPE } from "../common/InputTextField";

const EnterEmailModal = ({
  handleClose = () => {},
  handleConfirm = () => {},
  enteredEmail,
  setEnteredEmail,
}) => {
  return (
    <div
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-[#1e1e4cf5]"
    >
      <div className="relative w-full max-w-2xl max-h-full m-auto flex justify-center mt-10 2xl:ps-[0] xl:ps-[0] lg:px-[0] md:ps-[0] sm:ps-[64px] ps-[64px]">
        <div className="z-70 flex flex-col gap-[26px] items-center justify-between p-7 md:p-5 border-b rounded-t dark:border-gray-600 bg-white w-[548px]">
          <div className="text-center flex flex-col gap-[24px] relative w-full">
            <Typography
              variant={TYPOGRAPHY_VARIANT.MOBILE_HEADING_4}
              className="self-start"
            >
              Email Confirmation
            </Typography>
            <InputTextField
              label={"Please provide your emial for confirmation"}
              labelType={LABEL_TYPE.OUTLINED}
              placeholder="Enter Your Email"
              type="text"
              value={enteredEmail}
              onChange={(e) => setEnteredEmail(e.target.value)}
            />
            <div className="flex gap-3 self-end">
              <Button
                onClick={handleClose}
                variant={BUTTON_VARIANT.SECONDARY}
                className="w-[170px]"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                variant={BUTTON_VARIANT.PRIMARY}
                className="w-[170px]"
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterEmailModal;
